import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import CSS
import "../../assets/css/landing/animate.css";
import "../../assets/css/landing/magnific-popup.css";
import "../../assets/css/landing/normalize.css";
import "../../assets/css/landing/responsive.css";
import "../../assets/css/landing/style.css";

// Imports img
import logo from "../../assets/img/landing/logo.jpg";
import banner from "../../assets/img/landing/banner.jpg";
import screen1 from "../../assets/img/landing/screen-1.jpg";
import si1 from "../../assets/img/landing/service-icon-1.png";
import si2 from "../../assets/img/landing/service-icon-2.png";
import si3 from "../../assets/img/landing/service-icon-3.png";
import parallax2 from "../../assets/img/landing/parallax-2.jpg";
import angleBg from "../../assets/img/landing/angle-bg.png";
import mobile2 from "../../assets/img/landing/mobile2.png";
import parallax6 from "../../assets/img/landing/parallax-6.jpg";

class LandingPage extends Component {
  menuOnTop() {
    let el = document.getElementById("mainmenu-area-test");

    if (window.pageYOffset > 0) {
      el.classList.remove("affix-top");
      el.classList.add("affix");
    } else {
      el.classList.add("affix-top");
      el.classList.remove("affix");
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.menuOnTop);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.menuOnTop);
  }

  render() {
    return (
      <div onScroll={e => this.menuOnTop(e)}>
        <div
          className="mainmenu-area affix-top"
          id="mainmenu-area-test"
          data-spy="affix"
          data-offset-top="100"
        >
          <div className="container">
            <nav
              className="navbar navbar-expand-md navbar-dark "
              id="primary-menu"
            >
              <a className="navbar-brand" href="#">
                <img
                  src={banner}
                  className="banner-white"
                  alt="Logo"
                  width="135"
                />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarsExample04"
                aria-controls="navbarsExample04"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>

              <div className="collapse navbar-collapse" id="navbarsExample04">
                <ul className="navbar-nav ml-auto">
                  <li className="active links">
                    <a href="#home-page">Inicio</a>
                  </li>
                  <li className="links">
                    <a href="#service-page">Servicios</a>
                  </li>

                  <li className="links">
                    <a href="#price-page">Precios</a>
                  </li>

                  <li className="links">
                    <a href="#contact-page">Contacto</a>
                  </li>
                  <li className="links">
                    <Link to="/Login">Iniciar sesión</Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>

        <section>
          <header
            className="header-area overlay full-height relative v-center"
            id="home-page"
            style={{
              background:
                "#000000 url('" +
                parallax2 +
                "') no-repeat scroll center center / cover"
            }}
          >
            <div
              className="absolute anlge-bg"
              style={{
                background:
                  "url('" +
                  angleBg +
                  "') no-repeat scroll center bottom -120px / 100% auto"
              }}
            />
            <div className="container">
              <div className="row v-center">
                <div className="col-12 col-md-7 header-text">
                  <h2>SyBHS, registro de conducta de manejo.</h2>
                  <p>
                    Sabemos que nuestros choferes manejan apropiadamente, no se
                    exceden de tiempo al volante en conducción continua, se
                    descansa apropiadamente y en los lugares autorizados, se
                    turnan el volante el conductor principal y el auxiliar pero
                    no tenemos forma de generar la evidencia apropiada, SyBHS,
                    la aplicación móvil que le genera la información real de
                    cómo se llevó a cabo el trayecto en cada uno de sus puntos.
                  </p>
                </div>
                <div className="hidden-xs hidden-sm col-md-5 text-right">
                  <div className="screen-box screen-slider text-center">
                    <div
                      className="item"
                      style={{
                        background:
                          "url('" +
                          mobile2 +
                          "') no-repeat scroll center center / auto 100%"
                      }}
                    >
                      <img src={screen1} height="356" width="200" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </section>

        <section className="gray-bg section-padding" id="service-page">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="box">
                  <div className="box-icon">
                    <img src={si1} alt="" />
                  </div>
                  <h4>Descárguelo del PlayStore</h4>
                  <p>
                    La aplicación puede correr en cualquier dispositivo móvil,
                    se recomiendo sea una Tablet, Android por ahora.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="box">
                  <div className="box-icon">
                    <img src={si2} alt="" />
                  </div>
                  <h4>Aplicación Web</h4>
                  <p>
                    Programe los embarques, revise en tiempo real la manera en
                    que se lleva a cabo la entrega, genere alertas en caso de
                    violaciones.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="box">
                  <div className="box-icon">
                    <img src={si3} alt="" />
                  </div>
                  <h4>Históricos</h4>
                  <p>
                    Consulte la conducta de manejo a través del tiempo de sus
                    conductores y ¡prémielos!, además cumpla con los requisitos
                    de la Norma de manejo en carreteras federales.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="price-area overlay section-padding"
          id="price-page"
          style={{
            background:
              "url('" + parallax6 + "') no-repeat scroll center center / cover"
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center">
                <div className="page-title">
                  <h1>Precios</h1>
                  <i className="fas fa-arrows-alt-v" />
                  <p>¡Contáctenos para asesorarle y cotizarle!</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-md-6 text-center"
                style={{
                  color: "#515656",
                  padding: "15px"
                }}
              >
                <div style={{ background: "white", padding: "50px 40px" }}>
                  <h3 className="text-uppercase price-title">
                    Versión empresarial
                  </h3>
                  <hr />
                  <ul className="list-unstyled">
                    <li>
                      <strong className="amount">
                        ¡Contáctenos para asesorarle y cotizarle!
                      </strong>
                    </li>
                    <li>
                      Registre a sus conductores, asígneles embarques y registre
                      su actividad, consulte viajes pasados y el progreso del
                      actual.
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="col-md-6 text-center"
                style={{
                  color: "#515656",
                  padding: "15px"
                }}
              >
                <div
                  style={{
                    background: "white",
                    padding: "50px 40px",
                    height: "100%"
                  }}
                >
                  <h3 className="text-uppercase price-title">
                    Versión Solitaria
                  </h3>
                  <hr />
                  <ul className="list-unstyled">
                    <li>
                      <strong className="amount">
                        ¡Contáctenos para asesorarle y cotizarle!
                      </strong>
                    </li>
                    <li>
                      No requiere más que su dispositivo móvil, vea y genere
                      reportes individuales que podrá imprimir en su PC.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <footer className="footer-area relative sky-bg" id="contact-page">
            <div
              className="absolute footer-bg"
              style={{
                background:
                  "url('" +
                  angleBg +
                  "') no-repeat scroll center bottom / 100% auto"
              }}
            />
            <div className="footer-top">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 offset-md-3 text-center">
                    <div className="page-title">
                      <h1>Contáctanos</h1>
                      <i className="fas fa-arrows-alt-v" />
                      <p>
                        Permítanos saber de usted y poder de la mejor manera
                        ayudarlo, escribanos y podremos atenderlo personalmente
                        para ayudarlo a optimizar sus esfuerzos y que pueda dar
                        el mejor servicio.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <address className="side-icon-boxes">
                      <div className="side-icon-box">
                        <div className="side-icon">
                          <img src="images/location-arrow.png" alt="" />
                        </div>
                        <p>
                          <strong>Direccion: </strong> <br />
                          Mexico
                        </p>
                      </div>
                      <div className="side-icon-box">
                        <div className="side-icon">
                          <img src="images/phone-arrow.png" alt="" />
                        </div>
                        <p>
                          <strong>Telefono: </strong>
                          <a href="callto:8801812726495">+----------</a> <br />
                        </p>
                      </div>
                      <div className="side-icon-box">
                        <div className="side-icon">
                          <img src="images/mail-arrow.png" alt="" />
                        </div>
                        <p>
                          <strong>Correo: </strong>
                          <a href="mailto:youremail@example.com">
                            contacto@sybhs.com
                          </a>{" "}
                          <br />
                        </p>
                      </div>
                    </address>
                  </div>
                  <div className="col-12 col-md-8">
                    <form
                      action="process.php"
                      id="contact-form"
                      method="post"
                      className="contact-form"
                    >
                      <div className="form-double">
                        <input
                          type="text"
                          id="form-name"
                          name="form-name"
                          placeholder="Tu nombre"
                          className="form-control"
                          required="required"
                        />
                        <input
                          type="email"
                          id="form-email"
                          name="form-email"
                          className="form-control"
                          placeholder="Correo"
                          required="required"
                        />
                      </div>
                      <input
                        type="text"
                        id="form-subject"
                        name="form-subject"
                        className="form-control"
                        placeholder="Asunto"
                      />
                      <textarea
                        name="message"
                        id="form-message"
                        name="form-message"
                        rows="5"
                        className="form-control"
                        placeholder="Tu Mensaje"
                        required="required"
                      />
                      <button type="sibmit" className="button">
                        Enviar
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-middle">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 pull-right">
                    <ul className="social-menu text-right x-left">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-square" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-google" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-6">
                    <p>Siguenos en nuestras redes sociales.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-5 text-center">
                    <p>SyBHS &copy; 2019 Todos los derechos reservados</p>
                  </div>
                  <div className="col-1 text-center">
                    <p />
                  </div>
                  <div className="col-5 text-center">
                    <p>
                      {" "}
                      This template is made with{" "}
                      <i className="ti-heart" aria-hidden="true" /> by{" "}
                      <a href="https://colorlib.com">Colorlib&copy;</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </div>
    );
  }
}

export default LandingPage;
