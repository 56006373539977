import React, { Component } from "react";
import "../../assets/css/tables/tables.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";

export class Tbl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      columns: []
    };
  }

  render() {
    const { SearchBar } = Search;
    let nuevoBtn;

    if (this.props.nuevoTexto !== undefined) {
      nuevoBtn = (
        <Link to={`/${this.props.nuevoLink}`}>
          <button className="btn btn-primary">{this.props.nuevoTexto}</button>
        </Link>
      );
    } else {
      nuevoBtn = "";
    }

    if (this.props.data.length > 0) {
      return (
        <ToolkitProvider
          keyField="id"
          data={this.props.data}
          columns={this.props.columns}
          search
        >
          {props => (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <h3>
                    <div className="">
                      <span
                        className="refresh-empresas"
                        onClick={e => this.props.actualizar()}
                      >
                        Actualizar{" "}
                        <i className="fas fa-sync refresh-empresas" />
                      </span>
                    </div>
                  </h3>
                </div>

                <div className="col-md-4 text-center">{nuevoBtn}</div>

                <div className="col-md-4">
                  <SearchBar
                    {...props.searchProps}
                    className="mb-3"
                    placeholder="Buscar..."
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12">
                  <BootstrapTable
                    {...props.baseProps}
                    id="tabla-div"
                    keyField={this.props.id}
                    bootstrap4
                    wrapperClasses="table-responsive"
                    pagination={paginationFactory()}
                    classes="table-striped"
                    rowEvents={this.props.rowEvents}
                  />
                </div>
              </div>
            </div>
          )}
        </ToolkitProvider>
      );
    } else {
      return (
        <div>
          <div className="row">
            <div className="col-md-4">
              <h3>
                <div className="">
                  <span
                    className="refresh-empresas"
                    onClick={e => this.props.actualizar()}
                  >
                    Actualizar <i className="fas fa-sync refresh-empresas" />
                  </span>
                </div>
              </h3>
            </div>

            <div className="col-md-4 text-center">{nuevoBtn}</div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h3 className="text-center mt-3">
                No existen datos para mostrar
              </h3>
            </div>
          </div>
        </div>
      );
    }
  }
}
