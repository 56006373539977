import React, { Component } from "react";
import { withRouter } from "react-router";
import Cookies from "js-cookie";
import Loader from "../../modules/Layout/loader";
import "../../assets/css/Soporte/preguntas.css";

//
import Counter from "../Varios/counter";

// Sweetalert2
import Swal from "sweetalert2";

class PreguntasFrecuentes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      mensajeFAQ: "",
      isLoading: false
    };
  }

  async componentDidMount() {
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  validarFAQ(e) {
    e.preventDefault();

    if (this.state.mensajeFAQ === "") {
      Swal.fire({
        type: "error",
        text: "Por favor, ingresa tu duda para poder resolverla."
      });
    }
  }

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div>
          <Counter />
          <ol className="preguntas-frecuentes mt-3">
            <li className="mb-3">
              <p className="lead">
                <strong>
                  ¿Lorem ipsum dolor, sit amet consectetur adipisicing elit?
                </strong>
              </p>

              <div className="">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Tempore illum architecto obcaecati fugit labore id non
                  voluptates velit, exercitationem doloremque sint eveniet,
                  molestiae officia. Harum dicta excepturi hic sed totam?
                </p>
              </div>
            </li>

            <li className="mb-3">
              <p className="lead">
                <strong>
                  ¿Lorem ipsum dolor, sit amet consectetur adipisicing elit?
                </strong>
              </p>

              <div className="">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Tempore illum architecto obcaecati fugit labore id non
                  voluptates velit, exercitationem doloremque sint eveniet,
                  molestiae officia. Harum dicta excepturi hic sed totam?
                </p>
              </div>
            </li>

            <li className="mb-3">
              <p className="lead">
                <strong>
                  ¿Lorem ipsum dolor, sit amet consectetur adipisicing elit?
                </strong>
              </p>

              <div className="">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Tempore illum architecto obcaecati fugit labore id non
                  voluptates velit, exercitationem doloremque sint eveniet,
                  molestiae officia. Harum dicta excepturi hic sed totam?
                </p>
              </div>
            </li>

            <li className="mb-3">
              <p className="lead">
                <strong>
                  ¿Lorem ipsum dolor, sit amet consectetur adipisicing elit?
                </strong>
              </p>

              <div className="">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Tempore illum architecto obcaecati fugit labore id non
                  voluptates velit, exercitationem doloremque sint eveniet,
                  molestiae officia. Harum dicta excepturi hic sed totam?
                </p>
              </div>
            </li>

            <li className="mb-3">
              <p className="lead">
                <strong>
                  ¿Lorem ipsum dolor, sit amet consectetur adipisicing elit?
                </strong>
              </p>

              <div className="">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Tempore illum architecto obcaecati fugit labore id non
                  voluptates velit, exercitationem doloremque sint eveniet,
                  molestiae officia. Harum dicta excepturi hic sed totam?
                </p>
              </div>
            </li>

            <li className="mb-3">
              <p className="lead">
                <strong>
                  ¿Lorem ipsum dolor, sit amet consectetur adipisicing elit?
                </strong>
              </p>

              <div className="">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Tempore illum architecto obcaecati fugit labore id non
                  voluptates velit, exercitationem doloremque sint eveniet,
                  molestiae officia. Harum dicta excepturi hic sed totam?
                </p>
              </div>
            </li>
          </ol>

          <hr className="mt-5 mb-5" />

          <div className="container-fluid">
            <form id="form-faq" onSubmit={e => this.validarFAQ(e)}>
              <div className="row">
                <div className="col-md-12">
                  <p>
                    ¿Tienes otra duda? Mándanos tu pregunta y con gusto la
                    resolveremos personalmente:
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="mensajeFAQ"
                      cols="30"
                      rows="2"
                      style={{ resize: "none" }}
                      value={this.state.mensajeFAQ}
                      onChange={e => this.handleChange(e)}
                    />
                  </div>

                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary">Enviar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-md-12">{info}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PreguntasFrecuentes);
