import React, { Component } from "react";
import "../../assets/css/login/login.css";
import logo from "../../assets/img/banner.jpg";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

// Funciones
import { resetPassword } from "../../functions/login";

// Sweetalert
import Swal from "sweetalert2";

class ForgotPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailReset: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  async validarUsuario(e) {
    e.preventDefault();

    if (this.state.emailReset === "") {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "El campo no puede estar vacío."
      });

      return false;
    }

    let respuestaForgot = await resetPassword(this.state.emailReset);

    if (respuestaForgot !== undefined) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Revise que el correo proporcionado sea correcto."
      });
    } else {
      Swal.fire({
        type: "success",
        title: "¡Éxito!",
        text:
          "Por favor revise su bandeja de correo para recuperar su contraseña."
      });
    }
  }

  render() {
    return (
      <form
        action="#"
        id="form-input"
        method="POST"
        onSubmit={e => this.validarUsuario(e)}
      >
        <div className="form-group">
          <img src={logo} alt="SyBHS Logo" className="logo-login" />
        </div>

        <div className="form-group">
          <input
            type="email"
            className="form-control"
            name="emailReset"
            placeholder="Correo Electrónico"
            onChange={e => this.handleChange(e)}
            value={this.state.emailReset}
            autoComplete="off"
          />
        </div>

        <button className="btn btn-primary">Recuperar Contraseña</button>

        <div className="m-3">
          <Link to="/Login" style={{ color: "#495057" }}>
            Regresar a Iniciar Sesión
          </Link>
        </div>
      </form>
    );
  }
}

export default withRouter(ForgotPass);
