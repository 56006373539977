import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../../../functions/login";
import { getAllConductores } from "../../../functions/Administracion/conductores";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";

// Empresas
import { getAllEmpresas } from "../../../functions/Gestion/empresas";

// Sweetalert2
import Swal from "sweetalert2";
import { registerUnidad } from "../../../functions/Produccion/unidades";

class UnidadesAlta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      listadoEmpresas: "",
      listadoConductores: "",
      empresaAlta: "",
      conductorAlta: "",
      numeroTrailerAlta: "",
      numeroTractorAlta: "",
      marcaAlta: "",
      modeloAlta: "",
      placasAlta: "",
      NIVAlta: "",
      fotoAlta: "",
      fotoPreview: ""
    };
  }

  async componentWillMount() {
    let listadoEmpresas = await getAllEmpresas(
      JSON.parse(Cookies.get("usuario"))
    );

    let listadoConductores = await getAllConductores(
      JSON.parse(Cookies.get("usuario"))
    );

    if (listadoEmpresas === 401 || listadoConductores === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    this.setState({
      listadoEmpresas: listadoEmpresas,
      empresaAlta: listadoEmpresas[0]["id"],
      listadoConductores: listadoConductores,
      conductorAlta: listadoConductores[0]["id"],
      isLoading: false
    });
  }

  async validarUnidad(e) {
    e.preventDefault();

    let nuevaUnidad = {
      empresaAlta: this.state.empresaAlta,
      conductorAlta: this.state.conductorAlta,
      numeroTrailerAlta: this.state.numeroTrailerAlta,
      numeroTractorAlta: this.state.numeroTractorAlta,
      marcaAlta: this.state.marcaAlta,
      modeloAlta: this.state.modeloAlta,
      placasAlta: this.state.placasAlta,
      NIVAlta: this.state.NIVAlta,
      fotoAlta: this.state.fotoAlta
    };

    if (
      nuevaUnidad["empresaAlta"] === "" ||
      nuevaUnidad["conductorAlta"] === "" ||
      nuevaUnidad["numeroTrailerAlta"] === "" ||
      nuevaUnidad["numeroTractorAlta"] === "" ||
      nuevaUnidad["marcaAlta"] === "" ||
      nuevaUnidad["modeloAlta"] === "" ||
      nuevaUnidad["placasAlta"] === "" ||
      nuevaUnidad["NIVAlta"] === "" ||
      nuevaUnidad["fotoAlta"] === ""
    ) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Favor de completar los campos de registro de Unidad"
      });

      return false;
    }

    const regex = /[A-HJ-NPR-Z0-9]{17}/gi;

    if (!regex.test(nuevaUnidad["NIVAlta"])) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "El NIV no es válido. Por favor, verifíquelo."
      });

      return false;
    }

    this.setState({
      isLoading: true
    });

    let registroUnidadResponse = await registerUnidad(
      JSON.parse(Cookies.get("usuario")),
      nuevaUnidad
    );

    if (registroUnidadResponse === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    this.setState({
      isLoading: false
    });

    if (registroUnidadResponse === "") {
      Swal.fire({
        type: "success",
        title: "¡Éxito!",
        text: "La Unidad a sido creada exitosamente."
      });
    } else {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "La Unidad no pudo ser creado, por favor intente de nuevo."
      });

      return false;
    }

    this.setState({
      empresaAlta: "",
      nombreCompletoAlta: "",
      nombreUsuarioAlta: "",
      contraseniaAlta: "",
      correoAlta: "",
      rolAlta: "",
      fotoAlta: "",
      fotoPreview: ""
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeFile = async e => {
    let file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        fotoAlta: file,
        fotoPreview: reader.result
      });
    };
  };

  crearOptionEmpresa() {
    let listadoEmpresas = this.state.listadoEmpresas;

    return Object.keys(listadoEmpresas).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            defaultValue
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      }
    });
  }

  crearOptionConductores() {
    let listadoConductores = this.state.listadoConductores;

    return Object.keys(listadoConductores).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            defaultValue
            key={listadoConductores[keyName]["id"]}
            value={listadoConductores[keyName]["id"]}
          >
            {listadoConductores[keyName]["nombre"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoConductores[keyName]["id"]}
            value={listadoConductores[keyName]["id"]}
          >
            {listadoConductores[keyName]["nombre"]}
          </option>
        );
      }
    });
  }

  render() {
    let info, imagenPreview;

    if (this.state.fotoAlta === "") {
      imagenPreview = "";
    } else {
      imagenPreview = (
        <img
          src={this.state.fotoPreview}
          alt="Logo Preview"
          style={{ maxWidth: "250px" }}
        />
      );
    }

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <form action="#" onSubmit={e => this.validarUnidad(e)}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Empresa:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <select
                          name="empresaAlta"
                          className="form-control"
                          value={this.state.empresaAlta}
                          onChange={e => this.handleChange(e)}
                        >
                          {this.crearOptionEmpresa()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Conductor:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <select
                          name="conductorAlta"
                          className="form-control"
                          value={this.state.conductorAlta}
                          onChange={e => this.handleChange(e)}
                        >
                          {this.crearOptionConductores()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Número del Trailer:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="numeroTrailerAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.numeroTrailerAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Número de Tractor:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="numeroTractorAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.numeroTractorAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Marca:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="marcaAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.marcaAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Modelo:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="modeloAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.modeloAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Placas:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="placasAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.placasAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          NIV:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="NIVAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.NIVAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Foto Unidad:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <div className="form-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input custom-file-label"
                              id="logoAlta"
                              name="fotoAlta"
                              onChange={e => this.handleChangeFile(e)}
                              autoComplete="off"
                              accept="image/*"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="logoAlta"
                            >
                              Escoge una imagen
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Imagen:
                        </label>
                      </div>

                      <div className="col-md-9">{imagenPreview}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center">
                <button className="btn btn-primary">Registrar</button>
              </div>
            </div>
          </div>
        </form>
      );
    }

    return (
      <div className="card mt-3">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between">
                <h3 className="card-title">Alta de Unidad</h3>
                <Link to={`${process.env.PUBLIC_URL}/Unidades`}>
                  <button className="btn btn-primary">
                    Listado de Unidades
                  </button>
                </Link>
              </div>
            </div>

            <div className="row">
              <hr />
            </div>

            {info}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UnidadesAlta);
