import React, { Component } from "react";
import { Link } from "react-router-dom";

class Gestion extends Component {
  render() {
    return (
      <li className="">
        <a
          href="#gestion"
          data-target="#gestion"
          data-toggle="collapse"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          <i className="fas fa-users" />{" "}
          <span className="menu-title">Gestión</span>
        </a>
        <ul
          className="collapse list-unstyled main-menu"
          data-parent="#sidebar"
          id="gestion"
        >
          <li className="nav-item">
            <Link className="nav-link" to="/Empresas">
              Empresas
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Usuarios">
              Usuarios
            </Link>
          </li>
        </ul>

        <div className="active-submenu" data-parent="#sidebar">
          <ul className="list-unstyled list-not-active">
            <li className="nav-item">
              <p className="m-1">
                <strong>Gestión</strong>
              </p>

              <hr className="m-1" />
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/Empresas">
                Empresas
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Usuarios">
                Usuarios
              </Link>
            </li>
          </ul>
        </div>
      </li>
    );
  }
}

export default Gestion;
