import React, { Component } from "react";
import NoUser from "../../assets/img/NoUser.jpg";
import "../../assets/css/header/header.css";
import Cookie from "js-cookie";

// Funcionales
import { logout } from "../../functions/login";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nombreUser: "",
      nombreEmpresa: "",
      imgEmpresa: ""
    };
  }

  cerrarSesion(e) {
    e.preventDefault();
    logout();
    this.props.history.push("/Login");
  }

  getInfoUsuario() {
    let infoCookie = JSON.parse(Cookie.get("usuario"));

    if (infoCookie) {
      this.setState({
        nombreUser: infoCookie["name"],
        nombreEmpresa: infoCookie["businessName"],
        imgEmpresa: window.sessionStorage.getItem("businessLogo")
      });
    }
  }

  componentDidMount() {
    this.getInfoUsuario();
  }

  render() {
    let titlePage;

    if (this.props.title.length <= 15) {
      titlePage = (
        <div>
          <h1 className="text-center titlePage">{this.props.title}</h1>
        </div>
      );
    } else {
      titlePage = (
        <div>
          <h3 className="text-center titlePage">{this.props.title}</h3>
        </div>
      );
    }

    return (
      <div className="header-menu">
        <div className="container-fluid p-1">
          <div className="col-md-12">
            <div className="row">
              <div className="col-4 col-md-4 text-left mt-3">
                <Link to="/Mi-Empresa">
                  <img
                    src={this.state.imgEmpresa}
                    alt="Logo Empresa"
                    className="img-logo-empresa"
                  />
                  <span className="ml-2">{this.state.nombreEmpresa}</span>
                </Link>
              </div>

              <div className="col-4 col-md-4 d-flex justify-content-center align-items-center">
                <div className="text-center pt-1">{titlePage}</div>
              </div>

              <div className="col-4 col-md-4 justify-content-center mt-md-0 d-flex justify-content-md-end align-items-center  mt-3">
                <div
                  className="dropdown mr-4 nombreUsuario"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="mr-2 ">{this.state.nombreUser}</span>
                  <img
                    src={NoUser}
                    alt="Imagen Usuario"
                    className="img-usuario rounded-circle"
                  />
                </div>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <Link to="/Perfil" className="dropdown-item">
                    <span>
                      <i className="fas fa-user-alt" /> Perfil
                    </span>
                  </Link>

                  <Link to="/Configuracion" className="dropdown-item">
                    <span>
                      <i className="fas fa-cog" /> Configuración
                    </span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={e => this.cerrarSesion(e)}
                  >
                    <span>
                      <i className="fas fa-power-off" /> Cerrar Sesión
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
