import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../../../functions/login";
import { getAllEmpresas } from "../../../functions/Gestion/empresas";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";

// Sweetalert2
import Swal from "sweetalert2";
import {
  getConductor,
  editarConductor
} from "../../../functions/Administracion/conductores";

class ConductorEdita extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      isEditing: false,
      isLoading: true,
      idConductor: "",
      listadoEmpresas: "",
      empresaAlta: "",
      nombreCompletoAlta: "",
      nombreUsuarioAlta: "",
      correoAlta: "",
      contraseniaAlta: "",
      numeroLicenciaAlta: "",
      fechaLicenciaAlta: "",
      rulsetAlta: "Cargo",
      fotoAlta: "",
      fotoPreview: "",
      isFotoChanged: false
    };
  }

  async componentDidMount() {
    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    let listadoEmpresas = await getAllEmpresas(
      JSON.parse(Cookies.get("usuario"))
    );

    let conductorInfo = await getConductor(
      JSON.parse(Cookies.get("usuario")),
      this.props.match.params.id
    );

    if (listadoEmpresas === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    let date = new Date(conductorInfo["licenseDate"])
      .toISOString()
      .substr(0, 10);

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);

    this.setState({
      listadoEmpresas: listadoEmpresas,
      empresaAlta: conductorInfo["user"]["business"],
      numeroLicenciaAlta: conductorInfo["licenseNumber"],
      fechaLicenciaAlta: date,
      rulsetAlta: conductorInfo["ruleSetType"],
      nombreCompletoAlta: conductorInfo["user"]["fullName"],
      nombreUsuarioAlta: conductorInfo["user"]["userName"],
      correoAlta: conductorInfo["user"]["email"],
      fotoAlta: conductorInfo["user"]["picture"],
      fotoPreview: conductorInfo["user"]["picture"],
      isLoading: false,
      idConductor: conductorInfo["id"]
    });
  }

  timeToLoad() {
    this.setState({
      timeLoad: this.state.timeLoad + 1
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeFile = async e => {
    let file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        fotoAlta: file,
        fotoPreview: reader.result,
        isFotoChanged: true
      });
    };
  };

  async validarConductor(e) {
    e.preventDefault();

    let nuevoConductor = {
      empresaAlta: this.state.empresaAlta,
      nombreCompletoAlta: this.state.nombreCompletoAlta,
      nombreUsuarioAlta: this.state.nombreUsuarioAlta,
      correoAlta: this.state.correoAlta,
      contraseniaAlta: this.state.contraseniaAlta,
      numeroLicenciaAlta: this.state.numeroLicenciaAlta,
      fechaLicenciaAlta: this.state.fechaLicenciaAlta,
      rulsetAlta: this.state.rulsetAlta,
      fotoAlta: this.state.fotoAlta,
      isFotoChanged: this.state.isFotoChanged,
      idConductor: this.state.idConductor
    };

    if (
      nuevoConductor["empresaAlta"] === "" ||
      nuevoConductor["nombreCompletoAlta"] === "" ||
      nuevoConductor["nombreUsuarioAlta"] === "" ||
      nuevoConductor["correoAlta"] === "" ||
      nuevoConductor["contraseniaAlta"] === "" ||
      nuevoConductor["numeroLicenciaAlta"] === "" ||
      nuevoConductor["fechaLicenciaAlta"] === "" ||
      nuevoConductor["rulsetAlta"] === "" ||
      nuevoConductor["fotoAlta"] === "" ||
      nuevoConductor["fotoAlta"] === null
    ) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Favor de completar los campos de registro de Conductor"
      });

      return false;
    }

    Swal.fire({
      title: "¿Estas seguro?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Si, editar!",
      cancelButtonText: "Cancelar"
    }).then(async result => {
      if (result.value) {
        this.setState({
          isLoading: true
        });

        let registroConductorResponse = await editarConductor(
          JSON.parse(Cookies.get("usuario")),
          nuevoConductor
        );

        if (registroConductorResponse === 401) {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
          });

          logout();
          this.props.history.push("/Login");
          return false;
        }

        this.setState({
          isLoading: false
        });

        if (registroConductorResponse === "") {
          Swal.fire({
            type: "success",
            title: "¡Éxito!",
            text: "El Conductor a sido editado exitosamente."
          });
        } else {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text:
              "El Conductor no pudo ser editado, por favor intente de nuevo."
          });

          return false;
        }
      }
    });
  }

  crearOptionEmpresa() {
    let listadoEmpresas = this.state.listadoEmpresas;

    return Object.keys(listadoEmpresas).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            defaultValue
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      }
    });
  }

  async eliminarConductor(e) {
    e.preventDefault();

    Swal.fire({
      title: "¿Eliminar?",
      text: "Esta acción no podrá ser deshecha.",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!"
    }).then(async result => {
      if (result.value) {
        /* let respuestaEliminar = await eliminarConductor(
          JSON.parse(Cookies.get("usuario")),
          this.props.match.params.id
        ); */
      }
    });
  }

  habilitarEdicion() {
    this.setState({
      isEditing: !this.state.isEditing
    });
  }

  render() {
    let info, imagenPreview;

    if (this.state.fotoPreview === "" || this.state.fotoPreview === null) {
      imagenPreview = "";
    } else {
      imagenPreview = (
        <img
          src={this.state.fotoPreview}
          alt="Logo Preview"
          style={{ maxWidth: "250px" }}
        />
      );
    }

    if (this.state.isLoading) {
      info = (
        <div className="text-center w-100">
          <Loader />
        </div>
      );
    } else {
      info = (
        <div className="col-md-12">
          <div className="col-md-12">
            <div className="row">
              <div className="offset-3 col-6 ">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="" className="mt-2">
                        Empresa:
                      </label>
                    </div>

                    <div className="col-md-9">
                      <select
                        disabled={this.state.isEditing ? "" : "disabled"}
                        name="empresaAlta"
                        className="form-control"
                        value={this.state.empresaAlta}
                        onChange={e => this.handleChange(e)}
                      >
                        {this.crearOptionEmpresa()}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="offset-3 col-6 ">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="" className="mt-2">
                        Nombre completo:
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="text"
                        disabled={this.state.isEditing ? "" : "disabled"}
                        className="form-control"
                        name="nombreCompletoAlta"
                        onChange={e => this.handleChange(e)}
                        value={this.state.nombreCompletoAlta}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="offset-3 col-6 ">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="" className="mt-2">
                        Nombre de Usuario:
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        disabled={this.state.isEditing ? "" : "disabled"}
                        type="text"
                        className="form-control"
                        name="nombreUsuarioAlta"
                        onChange={e => this.handleChange(e)}
                        value={this.state.nombreUsuarioAlta}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="offset-3 col-6 ">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="" className="mt-2">
                        E-Mail:
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="email"
                        className="form-control"
                        disabled={this.state.isEditing ? "" : "disabled"}
                        name="correoAlta"
                        onChange={e => this.handleChange(e)}
                        value={this.state.correoAlta}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="offset-3 col-6 ">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="" className="mt-2">
                        Contraseña:
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="password"
                        className="form-control"
                        name="contraseniaAlta"
                        disabled={this.state.isEditing ? "" : "disabled"}
                        onChange={e => this.handleChange(e)}
                        value={this.state.contraseniaAlta}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="offset-3 col-6 ">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="" className="mt-2">
                        Número de Licencia:
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        name="numeroLicenciaAlta"
                        disabled={this.state.isEditing ? "" : "disabled"}
                        onChange={e => this.handleChange(e)}
                        value={this.state.numeroLicenciaAlta}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="offset-3 col-6 ">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="" className="mt-2">
                        Fecha de Licencia:
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="date"
                        className="form-control"
                        name="fechaLicenciaAlta"
                        disabled={this.state.isEditing ? "" : "disabled"}
                        onChange={e => this.handleChange(e)}
                        value={this.state.fechaLicenciaAlta}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="offset-3 col-6 ">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="" className="mt-2">
                        Tipo de Ruleset:
                      </label>
                    </div>

                    <div className="col-md-9">
                      <select
                        name="rulsetAlta"
                        className="form-control"
                        value={this.state.rulsetAlta}
                        disabled={this.state.isEditing ? "" : "disabled"}
                        onChange={e => this.handleChange(e)}
                      >
                        <option value="Cargo">Cargo</option>
                        <option value="Passenger">Passenger</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="offset-3 col-6 ">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="" className="mt-2">
                        Foto de Usuario:
                      </label>
                    </div>

                    <div className="col-md-9">
                      <div className="form-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input custom-file-label"
                            id="logoAlta"
                            name="fotoAlta"
                            disabled={this.state.isEditing ? "" : "disabled"}
                            onChange={e => this.handleChangeFile(e)}
                            autoComplete="off"
                            accept="image/*"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="logoAlta"
                          >
                            Escoge una imagen
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="offset-3 col-6 ">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="" className="mt-2">
                        Imagen:
                      </label>
                    </div>

                    <div className="col-md-9">{imagenPreview}</div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 text-center ${
                this.state.isEditing ? "d-flex" : "d-none"
              } justify-content-between`}
            >
              <button
                type="button"
                className="btn btn-danger"
                onClick={e => this.habilitarEdicion(e)}
              >
                Cancelar
              </button>

              <button type="submit" className="btn btn-primary">
                Confirmar
              </button>
            </div>

            <div
              className={`col-md-12 text-center ${
                this.state.isEditing ? "d-none" : "d-flex"
              } justify-content-between`}
            >
              <button
                type="button"
                className="btn btn-danger"
                onClick={e => this.eliminarConductor(e)}
              >
                Eliminar
              </button>

              <button
                type="button"
                onClick={e => this.habilitarEdicion(e)}
                className="btn btn-primary"
              >
                Editar
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <Link to={`${process.env.PUBLIC_URL}/Conductores`}>
                  <button className="btn btn-primary">
                    Listado Conductores
                  </button>
                </Link>
              </div>
            </div>

            <form
              className="mt-5"
              action="#"
              onSubmit={e => this.validarConductor(e)}
            >
              <div className="row">{info}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConductorEdita);
