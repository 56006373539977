import React, { Component } from "react";
import { Link } from "react-router-dom";

class Reportes extends Component {
  render() {
    return (
      <li className="">
        <a
          href="#reportes"
          data-toggle="collapse"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          <i className="fas fa-check-circle" />

          <span className="menu-title">Reportes</span>
        </a>

        <ul
          className="collapse list-unstyled main-menu"
          data-parent="#sidebar"
          id="reportes"
        >
          <li className="nav-item">
            <Link className="nav-link" to="/Reporte-Violaciones">
              Violaciones por Conductor
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Reporte-Conducta-Choferes">
              Conducta de Conductores
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Reporte-Embarques-Unidad">
              Embarques por Unidad
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/Reporte-Embarques-Conductor">
              Embarques por Conductor
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/Reporte-Embarques-Lugar">
              Embarques por Lugar
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/Reporte-Lugar-Autorizado-Ciudad">
              Lugares Autorizados por Ciudad
            </Link>
          </li>
        </ul>

        <div className="active-submenu reporte-submenu">
          <ul className="list-unstyled list-not-active">
            <li className="nav-item">
              <p className="m-1">
                <strong>Reportes</strong>
              </p>

              <hr className="m-1" />
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/Reporte-Violaciones">
                Violaciones por Conductor
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Reporte-Conducta-Choferes">
                Conducta de Conductores
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Reporte-Embarques-Unidad">
                Embarques por Unidad
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/Reporte-Embarques-Conductor">
                Embarques por Conductor
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/Reporte-Embarques-Lugar">
                Embarques por Lugar
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/Reporte-Lugar-Autorizado-Ciudad">
                Lugares Autorizados por Ciudad
              </Link>
            </li>
          </ul>
        </div>
      </li>
    );
  }
}

export default Reportes;
