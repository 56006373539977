import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../../../functions/login";
import { registerUsuario } from "../../../functions/Gestion/usuarios";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";

// Empresas
import { getAllEmpresas } from "../../../functions/Gestion/empresas";

// Sweetalert2
import Swal from "sweetalert2";

class UsuarioAlta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      listadoEmpresas: "",
      empresaAlta: "",
      nombreCompletoAlta: "",
      nombreUsuarioAlta: "",
      contraseniaAlta: "",
      correoAlta: "",
      telAlta: "",
      rolAlta: "",
      roles: [
        {
          nombre: "Superadmin"
        },
        {
          nombre: "Admin"
        },
        {
          nombre: "Manager"
        },
        {
          nombre: "Dispatcher"
        }
      ],
      fotoAlta: "",
      fotoPreview: ""
    };
  }

  async componentWillMount() {
    let listadoEmpresas = await getAllEmpresas(
      JSON.parse(Cookies.get("usuario"))
    );

    if (listadoEmpresas === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    this.setState({
      isLoading: false,
      listadoEmpresas: listadoEmpresas,
      empresaAlta: listadoEmpresas[0]["id"],
      rolAlta: this.state.roles[0]["nombre"]
    });
  }

  async validarUsuario(e) {
    e.preventDefault();

    let nuevoUsuario = {
      empresaAlta: this.state.empresaAlta,
      nombreCompletoAlta: this.state.nombreCompletoAlta,
      nombreUsuarioAlta: this.state.nombreUsuarioAlta,
      contraseniaAlta: this.state.contraseniaAlta,
      correoAlta: this.state.correoAlta,
      telAlta: this.state.telAlta,
      rolAlta: this.state.rolAlta,
      fotoAlta: this.state.fotoAlta
    };

    if (
      nuevoUsuario["empresaAlta"] === "" ||
      nuevoUsuario["nombreCompletoAlta"] === "" ||
      nuevoUsuario["nombreUsuarioAlta"] === "" ||
      nuevoUsuario["contraseniaAlta"] === "" ||
      nuevoUsuario["correoAlta"] === "" ||
      nuevoUsuario["rolAlta"] === "" ||
      nuevoUsuario["fotoAlta"] === ""
    ) {
      alert("Favor de completar los campos de registro de Usuario");
      return false;
    }

    this.setState({
      isLoading: true
    });

    let registroUsuarioResponse = await registerUsuario(
      JSON.parse(Cookies.get("usuario")),
      nuevoUsuario
    );

    if (registroUsuarioResponse === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    this.setState({
      isLoading: false
    });

    if (registroUsuarioResponse === "Usuario creado") {
      Swal.fire({
        type: "success",
        title: "¡Éxito!",
        text: "El Usuario a sido creado exitosamente."
      });
    } else {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "El Usuario no pudo ser creado, por favor intente de nuevo."
      });

      return false;
    }

    this.setState({
      empresaAlta: "",
      nombreCompletoAlta: "",
      nombreUsuarioAlta: "",
      contraseniaAlta: "",
      correoAlta: "",
      rolAlta: "",
      fotoAlta: "",
      fotoPreview: ""
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeFile = async e => {
    let file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        fotoAlta: file,
        fotoPreview: reader.result
      });
    };
  };

  crearOptionEmpresa() {
    let listadoEmpresas = this.state.listadoEmpresas;

    return Object.keys(listadoEmpresas).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            defaultValue
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      }
    });
  }

  crearOptionRoles() {
    let listadoRoles = this.state.roles;

    return Object.keys(listadoRoles).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            defaultValue
            key={listadoRoles[keyName]["nombre"]}
            value={listadoRoles[keyName]["nombre"]}
          >
            {listadoRoles[keyName]["nombre"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoRoles[keyName]["nombre"]}
            value={listadoRoles[keyName]["nombre"]}
          >
            {listadoRoles[keyName]["nombre"]}
          </option>
        );
      }
    });
  }

  render() {
    let info, imagenPreview;

    if (this.state.fotoAlta === "") {
      imagenPreview = "";
    } else {
      imagenPreview = (
        <img
          src={this.state.fotoPreview}
          alt="Logo Preview"
          style={{ maxWidth: "250px" }}
        />
      );
    }

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <form action="#" onSubmit={e => this.validarUsuario(e)}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Empresa:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <select
                          name="empresaAlta"
                          className="form-control"
                          value={this.state.empresaAlta}
                          onChange={e => this.handleChange(e)}
                        >
                          {this.crearOptionEmpresa()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Nombre completo:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="nombreCompletoAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.nombreCompletoAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Nombre de Usuario:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="nombreUsuarioAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.nombreUsuarioAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Contraseña:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="password"
                          className="form-control"
                          name="contraseniaAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.contraseniaAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          E-Mail:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="email"
                          className="form-control"
                          name="correoAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.correoAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Teléfono:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="number"
                          className="form-control"
                          name="telAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.telAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Rol:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <select
                          name="rolAlta"
                          className="form-control"
                          value={this.state.rolAlta}
                          onChange={e => this.handleChange(e)}
                        >
                          {this.crearOptionRoles()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Foto de Usuario:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <div className="form-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input custom-file-label"
                              id="logoAlta"
                              name="fotoAlta"
                              onChange={e => this.handleChangeFile(e)}
                              autoComplete="off"
                              accept="image/*"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="logoAlta"
                            >
                              Escoge una imagen
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Imagen:
                        </label>
                      </div>

                      <div className="col-md-9">{imagenPreview}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center">
                <button className="btn btn-primary">Registrar</button>
              </div>
            </div>
          </div>
        </form>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <Link to={`${process.env.PUBLIC_URL}/Usuarios`}>
                  <button className="btn btn-primary">
                    Listado de Usuarios
                  </button>
                </Link>
              </div>
            </div>

            <div className="row">
              <hr />
            </div>

            {info}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UsuarioAlta);
