export const roles = {
  Driver: 6,
  Developer: 5,
  Superadmin: 4,
  Admin: 3,
  Manager: 2,
  Dispatcher: 1
};

class user {
  constructor(
    username,
    name,
    email,
    range,
    token,
    businessId,
    businessName,
    bussinesAddress,
    bussinesLogo,
    picture
  ) {
    this.name = name;
    this.email = email;
    this.range = roles[range];
    this.token = "Bearer " + token;
    this.business = businessId;
    this.businessName = businessName;

    this.bussinesAddress = bussinesAddress;

    this.bussinesLogo = bussinesLogo;

    this.picture = picture;
  }

  tostring() {
    return (
      this.username +
      " " +
      this.email +
      " " +
      this.range +
      " " +
      this.token +
      " "
    );
  }
}

export default user;
