import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../../../functions/login";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";
import "../../../assets/css/Dev/dev.css";

// Sweetalert2
import Swal from "sweetalert2";

// Funciones
import { getAppError } from "../../../functions/Dev/apperrors";

class DetalleAppError extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      description: "",
      extra: "",
      stackTrace: ""
    };
  }

  async componentDidMount() {
    let responseAppError = await getAppError(
      JSON.parse(Cookies.get("usuario")),
      this.props.match.params.id
    );

    if (responseAppError === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    this.setState({
      isLoading: false,
      description:
        responseAppError["description"] === ""
          ? "N/A"
          : responseAppError["description"],
      extra:
        responseAppError["extra"] === "" ? "N/A" : responseAppError["extra"],
      stackTrace: responseAppError["stackTrace"]
    });
  }

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="col-md-6">
              <h4 className="text-center">Descripción</h4>

              <pre>{this.state.description}</pre>
            </div>

            <div className="col-md-6">
              <h4 className="text-center">Extra</h4>

              <pre>{this.state.extra}</pre>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h4 className="text-center">StackTrace</h4>

              <pre>{this.state.stackTrace}</pre>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card mt-3">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <Link to="/AppErrors">
                  <button className="btn btn-primary">Listado Errores</button>
                </Link>
              </div>
            </div>

            <div className="row">{info}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DetalleAppError);
