import React, { Component } from "react";
import { withRouter } from "react-router";
import { logout } from "../../../functions/login";
import { getAllUnidades } from "../../../functions/Produccion/unidades";
import Cookies from "js-cookie";
import Descargas from "../../Layout/descargas";

import Loader from "../../Layout/loader";

// Datatables
import { Tbl } from "../../Layout/table";

// Funciones
import { formatImg } from "../../../functions/formato-imagen";

class Unidades extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      dataSet: [],
      id: "id",
      columns: [
        {
          dataField: "imagen",
          text: "Imagen",
          formatter: formatImg,
          sort: false,
          classes: "text-center"
        },
        {
          dataField: "conductor",
          text: "Conductor",
          sort: true
        },
        {
          dataField: "trailer",
          text: "Trailer",
          sort: true
        },
        {
          dataField: "tractor",
          text: "Tractor",
          sort: true
        },
        {
          dataField: "marca",
          text: "Marca",
          sort: true
        },
        {
          dataField: "modelo",
          text: "Modelo",
          sort: true
        },
        {
          dataField: "placas",
          text: "Placas",
          sort: true
        },
        {
          dataField: "niv",
          text: "Niv",
          sort: true
        }
      ],
      rowEvents: {
        onClick: (e, row, rowIndex) => {
          this.props.history.push(
            `${process.env.PUBLIC_URL}/Editar-Unidad/${row["id"]}`
          );
        }
      },
      isLoading: true
    };

    this.refreshUnidades = this.refreshUnidades.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    this.setState({
      dataSet: await this.obtenerUnidades(),
      isLoading: false
    });

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  timeToLoad() {
    this.setState({
      timeLoad: this.state.timeLoad + 1
    });
  }

  async obtenerUnidades() {
    let dataSetUnidades = await getAllUnidades(
      JSON.parse(Cookies.get("usuario"))
    );

    if (dataSetUnidades === 401) {
      logout();
      this.props.history.push("/Login");
      return false;
    }

    return dataSetUnidades;
  }

  async refreshUnidades(e) {
    this.setState({
      isLoading: true
    });

    this.setState({
      dataSet: await this.obtenerUnidades(),
      isLoading: false
    });
  }

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Tbl
                id={this.state.id}
                columns={this.state.columns}
                data={this.state.dataSet}
                rowEvents={this.state.rowEvents}
                nuevoTexto="Nueva Unidad"
                nuevoLink="Alta-Unidad"
                actualizar={this.refreshUnidades}
              />

              {Object.entries(this.state.dataSet).length === 0 ? (
                ""
              ) : (
                <Descargas dataSet={this.state.dataSet} nombre="Unidades" />
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body loader-inside">{info}</div>
      </div>
    );
  }
}

export default withRouter(Unidades);
