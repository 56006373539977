import axios from "axios";

export const getAllLogs = usuario => {
  let logsResponse = axios
    .get("https://sybhs.azurewebsites.net/Log", {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });

  return logsResponse;
};
