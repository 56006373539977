import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../../../functions/login";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";

// Funciones
import { formatImg } from "../../../functions/formato-imagen";
import {
  getAllUsuarios,
  formatConfirmado
} from "../../../functions/Gestion/usuarios";

// Datatables
import { Tbl } from "../../Layout/table";
import { finalizarDescarga } from "../../../functions/descargas";
import Descargas from "../../Layout/descargas";

class Usuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      dataSet: [],
      id: "id",
      columns: [
        {
          dataField: "foto",
          text: "Foto",
          formatter: formatImg,
          classes: "text-center",
          sort: false
        },
        {
          dataField: "nombre",
          text: "Nombre",
          sort: true
        },
        {
          dataField: "empresa",
          text: "Empresa",
          sort: true
        },
        {
          dataField: "correo",
          text: "Correo",
          sort: true
        },
        {
          dataField: "confirmado",
          text: "Confirmado",
          formatter: formatConfirmado,
          sort: true
        }
      ],
      rowEvents: {
        onClick: (e, row, rowIndex) => {
          this.props.history.push(
            `${process.env.PUBLIC_URL}/Editar-Usuario/${row["id"]}`
          );
        }
      },
      isLoading: true
    };

    this.refreshUsuarios = this.refreshUsuarios.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    this.setState({
      dataSet: await this.obtenerUsuarios(),
      isLoading: false
    });

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  timeToLoad() {
    this.setState({
      timeLoad: this.state.timeLoad + 1
    });
  }

  async obtenerUsuarios() {
    let dataSetUsuarios = await getAllUsuarios(
      JSON.parse(Cookies.get("usuario"))
    );

    if (dataSetUsuarios === 401) {
      logout();
      this.props.history.push("/Login");
      return false;
    }

    return dataSetUsuarios;
  }

  async refreshUsuarios(e) {
    this.setState({
      isLoading: true
    });

    this.setState({
      dataSet: await this.obtenerUsuarios(),
      isLoading: false
    });
  }

  // Alta de Usuarios
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeFile = async e => {
    let nombre = e.target.name;
    let file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        [nombre]: reader.result
      });
    };
  };

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Tbl
                id={this.state.id}
                columns={this.state.columns}
                data={this.state.dataSet}
                rowEvents={this.state.rowEvents}
                nuevoTexto="Nuevo Usuario"
                nuevoLink="Alta-Usuario"
                actualizar={this.refreshUsuarios}
              />

              {Object.entries(this.state.dataSet).length === 0 ? (
                ""
              ) : (
                <Descargas dataSet={this.state.dataSet} nombre="Usuarios" />
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body loader-inside">{info}</div>
      </div>
    );
  }
}

export default withRouter(Usuarios);
