import React, { Component } from "react";
import { withRouter } from "react-router";
import { logout } from "../../../functions/login";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";
import Descargas from "../../Layout/descargas";

// Sweetalert2
import Swal from "sweetalert2";

// Datatables
import { Tbl } from "../../Layout/table";

// Funciones
import { formatImg } from "../../../functions/formato-imagen";
import { getAllViolaciones } from "../../../functions/Operacion/Violaciones/violaciones";

class Violaciones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      dataSet: [],
      id: "id",
      columns: [
        {
          dataField: "",
          text: "Campo 1",
          formatter: formatImg,
          sort: false,
          classes: "text-center"
        },
        {
          dataField: "",
          text: "Campo 2",
          sort: true
        },
        {
          dataField: "",
          text: "Campo 3",
          sort: true
        }
      ],
      isLoading: true
    };

    this.refreshViolaciones = this.refreshViolaciones.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    this.setState({
      dataSet: await this.obtenerViolaciones(),
      isLoading: false
    });

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  timeToLoad() {
    this.setState({
      timeLoad: this.state.timeLoad + 1
    });
  }

  async obtenerViolaciones() {
    let dataSetViolaciones = await getAllViolaciones(
      JSON.parse(Cookies.get("usuario"))
    );

    if (dataSetViolaciones === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    return dataSetViolaciones;
  }

  async refreshViolaciones(e) {
    this.setState({
      isLoading: true
    });

    this.setState({
      dataSet: await this.obtenerViolaciones(),
      isLoading: false
    });
  }

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Tbl
                id={this.state.id}
                columns={this.state.columns}
                data={this.state.dataSet}
                rowEvents={this.state.rowEvents}
                actualizar={this.refreshViolaciones}
              />

              {Object.entries(this.state.dataSet).length === 0 ? (
                ""
              ) : (
                <Descargas dataSet={this.state.dataSet} nombre="Violaciones" />
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body loader-inside">{info}</div>
      </div>
    );
  }
}

export default withRouter(Violaciones);
