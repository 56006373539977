import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../../../functions/login";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";

// Empresas
import { getAllEmpresas } from "../../../functions/Gestion/empresas";

// Sweetalert2
import Swal from "sweetalert2";
import { registerLugar } from "../../../functions/Administracion/authplaces";

class AuthPlaceEdita extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      listadoEmpresas: "",
      empresaAlta: "",
      direccionAlta: "",
      latAlta: "",
      lonAlta: "",
      latRegex: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
      lonRegex: /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
    };
  }

  async componentWillMount() {
    let listadoEmpresas = await getAllEmpresas(
      JSON.parse(Cookies.get("usuario"))
    );

    if (listadoEmpresas === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    this.setState({
      listadoEmpresas: listadoEmpresas,
      empresaAlta: listadoEmpresas[0]["id"],
      isLoading: false
    });
  }

  async validarLugar(e) {
    e.preventDefault();

    let nuevoLugar = {
      empresaAlta: this.state.empresaAlta,
      direccionAlta: this.state.direccionAlta,
      latAlta: this.state.latAlta,
      lonAlta: this.state.lonAlta
    };

    let isInputEmpty = Object.keys(nuevoLugar).some(function(keyName) {
      if (nuevoLugar[keyName] === "") {
        return true;
      }
    });

    if (isInputEmpty) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Favor de completar los campos de registro de Lugar Autorizado"
      });

      return false;
    }

    if (!this.state.latRegex.test(nuevoLugar["latAlta"])) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "La Latitud es incorrecta."
      });

      return false;
    }

    if (!this.state.lonRegex.test(nuevoLugar["lonAlta"])) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "La Longitud es incorrecta."
      });

      return false;
    }

    this.setState({
      isLoading: true
    });

    let registroLugarResponse = await registerLugar(
      JSON.parse(Cookies.get("usuario")),
      nuevoLugar
    );

    console.log(registroLugarResponse);

    if (registroLugarResponse === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    this.setState({
      isLoading: false
    });

    if (registroLugarResponse === "") {
      Swal.fire({
        type: "success",
        title: "¡Éxito!",
        text: "El Lugar Autorizado a sido creado exitosamente."
      });
    } else {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text:
          "El Lugar Autorizado no pudo ser creado, por favor intente de nuevo."
      });

      return false;
    }

    this.setState({
      listadoEmpresas: "",
      empresaAlta: "",
      direccionAlta: "",
      latAlta: "",
      lonAlta: ""
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeNumber = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  crearOptionEmpresa() {
    let listadoEmpresas = this.state.listadoEmpresas;

    return Object.keys(listadoEmpresas).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            defaultValue
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      }
    });
  }

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <form action="#" onSubmit={e => this.validarLugar(e)}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Empresa:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <select
                          name="empresaAlta"
                          className="form-control"
                          value={this.state.empresaAlta}
                          onChange={e => this.handleChange(e)}
                        >
                          {this.crearOptionEmpresa()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Direccion:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="direccionAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.direccionAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Latitud:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="number"
                          className="form-control"
                          name="latAlta"
                          onChange={e => this.handleChangeNumber(e)}
                          value={this.state.latAlta}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Longitud:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="number"
                          className="form-control"
                          name="lonAlta"
                          onChange={e => this.handleChangeNumber(e)}
                          value={this.state.lonAlta}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center">
                <button className="btn btn-primary">Registrar</button>
              </div>
            </div>
          </div>
        </form>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <Link to="Lugares-Autorizados">
                  <button className="btn btn-primary">
                    Listado de Lugares Autorizados
                  </button>
                </Link>
              </div>
            </div>

            <div className="row">
              <hr />
            </div>

            {info}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AuthPlaceEdita);
