import React, { Component } from "react";
import { Link } from "react-router-dom";

class Administracion extends Component {
  render() {
    return (
      <li className="">
        <a
          href="#administracion"
          data-toggle="collapse"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          <i className="fas fa-book-open" />
          <span className="menu-title">Administración</span>
        </a>

        <ul
          className="collapse list-unstyled main-menu"
          data-parent="#sidebar"
          id="administracion"
        >
          <li className="nav-item">
            <Link to="/Conductores" className="nav-link">
              Conductores
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/Lugares-Autorizados" className="nav-link">
              Lugares Autorizados
            </Link>
          </li>
        </ul>

        <div className="active-submenu">
          <ul className="list-unstyled list-not-active">
            <li className="nav-item">
              <p className="m-1">
                <strong>Administración</strong>
              </p>

              <hr className="m-1" />
            </li>

            <li className="nav-item">
              <Link to="/Conductores" className="nav-link">
                Conductores
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/Lugares-Autorizados" className="nav-link">
                Lugares Autorizados
              </Link>
            </li>
          </ul>
        </div>
      </li>
    );
  }
}

export default Administracion;
