import React, { Component } from "react";
import { withRouter } from "react-router";
import Cookies from "js-cookie";
import Loader from "../../modules/Layout/loader";

//
import Counter from "../Varios/counter";

// Sweetalert2
import Swal from "sweetalert2";

class ReportarError extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      isLoading: false,
      seccion: 0,
      imagenError: "",
      mensajeError: ""
    };
  }

  componentDidMount() {
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeFile = async e => {
    let file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        logoAlta: file,
        logoPreview: reader.result
      });
    };
  };

  formReporte = e => {
    e.preventDefault();

    if (this.state.seccion === 0) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Por favor, selecciona la sección donde ocurrió el error."
      });

      return false;
    }

    if (this.state.mensajeError === "") {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Por favor, describe el error."
      });

      return false;
    }
  };

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div>
          <Counter />

          <div className="div-title mt-3">
            <p className="lead">
              <strong>
                Por favor, dinos en que Sección y la naturaleza del error que
                quieres reportar.
              </strong>
            </p>
          </div>

          <form
            action="#"
            method="post"
            id="form-contacto"
            className="mt-4"
            onSubmit={e => this.formReporte(e)}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="" className="mt-2">
                        <strong>Pantalla:</strong>
                      </label>
                    </div>

                    <div className="col-md-9">
                      <select
                        name="seccion"
                        className="form-control"
                        value={this.state.empresaAlta}
                        onChange={e => this.handleChange(e)}
                      >
                        <option value="0" selected disabled>
                          Selecciona...
                        </option>
                        <option value="1">Desarrollo</option>
                        <option value="2">Gestión</option>
                        <option value="3">Administración</option>
                        <option value="4">Producción</option>
                        <option value="5">Operación</option>
                        <option value="6">Reportes</option>
                        <option value="7">App</option>
                        <option value="8">Otro</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <textarea
                    style={{ resize: "none" }}
                    name="mensajeError"
                    cols="30"
                    rows="10"
                    className="form-control"
                    placeholder="Describe el error..."
                    onChange={e => this.handleChange(e)}
                    value={this.state.contactoMensaje}
                  />
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input custom-file-label"
                          id="logoAlta"
                          name="logoAlta"
                          onChange={e => this.handleChangeFile(e)}
                          autoComplete="off"
                          accept="image/*"
                        />
                        <label className="custom-file-label" htmlFor="logoAlta">
                          Escoge un archivo
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 text-md-right">
                    <button className="btn btn-primary">Enviar</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-md-12">{info}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReportarError);
