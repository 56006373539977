import axios from "axios";
import { uploadImagen } from "../formato-imagen";

export const getAllUnidades = usuario => {
  let unidadesResponse = axios
    .get("https://sybhs.azurewebsites.net/Profile", {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });

  return unidadesResponse;
};

export const registerUnidad = async (usuario, objUnidad) => {
  let urlImagen = await uploadImagen(usuario, objUnidad["fotoAlta"]);

  if (urlImagen === 401) {
    return 401;
  }

  let data = {
    business: objUnidad["empresaAlta"],
    driver: objUnidad["conductorAlta"],
    trailerNumber: objUnidad["numeroTrailerAlta"],
    tractorNumber: objUnidad["numeroTractorAlta"],
    tractorVIN: objUnidad["NIVAlta"],
    tractorBrand: objUnidad["marcaAlta"],
    tractorModel: objUnidad["modeloAlta"],
    tractorPlate: objUnidad["placasAlta"],
    unitImage: urlImagen
  };

  return axios
    .post("https://sybhs.azurewebsites.net/Profile", data, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export const getUnidad = async (usuario, idUnidad) => {
  return await axios
    .get("https://sybhs.azurewebsites.net/Profile/" + idUnidad, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export const editarUnidad = async (usuario, objUnidad) => {
  let urlImagen = "";

  if (objUnidad["isFotoChanged"]) {
    urlImagen = await uploadImagen(usuario, objUnidad["fotoAlta"]);

    if (urlImagen === 401) {
      return 401;
    }
  } else {
    urlImagen = objUnidad["fotoAlta"];
  }

  let data = {
    business: objUnidad["empresaAlta"],
    driver: objUnidad["conductorAlta"],
    trailerNumber: objUnidad["numeroTrailerAlta"],
    tractorNumber: objUnidad["numeroTractorAlta"],
    tractorVIN: objUnidad["NIVAlta"],
    tractorBrand: objUnidad["marcaAlta"],
    tractorModel: objUnidad["modeloAlta"],
    tractorPlate: objUnidad["placasAlta"],
    id: objUnidad["idUnidad"],
    unitImage: urlImagen
  };

  return axios
    .put("https://sybhs.azurewebsites.net/Profile", data, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error;
    });
};

export const eliminarUnidad = async (usuario, idUnidad) => {
  return await axios
    .delete("https://sybhs.azurewebsites.net/Profile/",{id: idUnidad}, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};
