import React, { Component } from "react";
import Cookie from "js-cookie";
import NoUser from "../../assets/img/NoUser.jpg";
import "../../assets/css/perfil/perfil.css";
import { cambiarContrasenia } from "../../functions/Perfil/perfil";
import { logout } from "../../functions/login";

import Swal from "sweetalert2";

class Perfil extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      imgEmpresa: "",
      nombreEmpresa: "",
      dirEmpresa: "",
      imgPerfil: "",
      nombrePerfil: "",
      correoPerfil: "",

      imgPerfilAlta: "",
      imgPerfilPreview: "",
      btnGuardarImgPerfil: false,

      contraseniaActual: "",
      contraseniaNueva: "",
      contraseniaNuevaValidacion: "",

      emailActual: "",
      emailNuevo: "",
      emailNuevoValidacion: "",

      telActual: "",
      telNuevo: "",
      telNuevoValidacion: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeFile = async e => {
    let file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        imgPerfilAlta: file,
        imgPerfilPreview: reader.result,
        btnGuardarImgPerfil: true
      });
    };
  };

  componentDidMount() {
    let usuario = JSON.parse(Cookie.get("usuario"));

    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    this.setState({
      imgEmpresa: window.sessionStorage.getItem("businessLogo"),
      nombreEmpresa: usuario["businessName"],
      dirEmpresa: usuario["bussinesAddress"],
      nombrePerfil: usuario["name"],
      correoPerfil: usuario["email"]
    });

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  async validarCambioPass(e) {
    e.preventDefault();

    let {
      contraseniaActual,
      contraseniaNueva,
      contraseniaNuevaValidacion
    } = this.state;

    if (
      contraseniaActual === "" ||
      contraseniaNueva === "" ||
      contraseniaNuevaValidacion === ""
    ) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text:
          "Por favor llene los campos para realizar el cambio de contraseña."
      });

      return false;
    }

    if (contraseniaActual === contraseniaNueva) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "La contraseña actual y la contraseña nueva son iguales."
      });

      return false;
    }

    if (contraseniaNueva !== contraseniaNuevaValidacion) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "La nueva contraseña no es igual en los ultimos campos."
      });

      return false;
    }

    let responseCambioPass = await cambiarContrasenia(
      JSON.parse(Cookie.get("usuario")),
      contraseniaActual,
      contraseniaNueva
    );

    if (responseCambioPass === 401) {
      logout();
      this.props.history.push("/Login");
      return false;
    }

    Swal.fire({
      type: "success",
      title: "¡Éxito!",
      text: "Cambio de contraseña exitoso."
    });

    this.setState({
      contraseniaActual: "",
      contraseniaNueva: "",
      contraseniaNuevaValidacion: ""
    });
  }

  async validarCambioCorreo(e) {
    e.preventDefault();

    let { emailActual, emailNuevo, emailNuevoValidacion } = this.state;

    if (
      emailActual === "" ||
      emailNuevo === "" ||
      emailNuevoValidacion === ""
    ) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Por favor llene los campos para realizar el cambio de correo."
      });
      return false;
    }

    if (emailActual === emailNuevo) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "El correo electrónico actual y el nuevo son iguales."
      });
      return false;
    }

    if (emailNuevo !== emailNuevoValidacion) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "El correo electrónico no es igual en los ultimos campos."
      });
      return false;
    }

    /* let responseCambioPass = await cambiarContrasenia(
      JSON.parse(Cookie.get("usuario")),
      contraseniaActual,
      contraseniaNueva
    );

    if (responseCambioPass === 401) {
      logout();
      this.props.history.push("/Login");
      return false;
    } */

    this.setState({
      emailActual: "",
      emailNuevo: "",
      emailNuevoValidacion: ""
    });
  }

  async validarCambioTel(e) {
    e.preventDefault();

    let { telActual, telNuevo, telNuevoValidacion } = this.state;

    if (telActual === "" || telNuevo === "" || telNuevoValidacion === "") {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Por favor llene los campos para realizar el cambio de teléfono."
      });
      return false;
    }

    if (telActual === telNuevo) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "El teléfono actual y el nuevo son iguales."
      });
      return false;
    }

    if (telNuevo !== telNuevoValidacion) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "El teléfono no es igual en los ultimos campos."
      });
      return false;
    }

    /* let responseCambioPass = await cambiarContrasenia(
      JSON.parse(Cookie.get("usuario")),
      contraseniaActual,
      contraseniaNueva
    );

    if (responseCambioPass === 401) {
      logout();
      this.props.history.push("/Login");
      return false;
    } */

    this.setState({
      telActual: "",
      telNuevo: "",
      telNuevoValidacion: ""
    });
  }

  cancelarCambioImg() {
    this.setState({
      imgPerfilAlta: "",
      imgPerfilPreview: "",
      btnGuardarImgPerfil: false
    });

    document.getElementById("imgPerfilAlta").value = "";
  }

  guardarImagen() {}

  render() {
    let imgPerfil = this.state.imgPerfil;
    let btnGuardar;

    if (this.state.imgPerfil === "") {
      imgPerfil = NoUser;
    }

    if (this.state.imgPerfilPreview !== "") {
      imgPerfil = this.state.imgPerfilPreview;
    }

    if (this.state.btnGuardarImgPerfil) {
      btnGuardar = (
        <div className="d-flex justify-content-between mb-3">
          <button
            onClick={e => this.cancelarCambioImg(e)}
            className="btn btn-danger"
          >
            Cancelar
          </button>
          <button className="btn btn-primary">Guardar</button>
        </div>
      );
    } else {
      btnGuardar = "";
    }

    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Datos personales</h1>

                  <div className="container-fluid">
                    <div className="col-md-12">
                      <img className="img-perfil" src={imgPerfil} alt="" />

                      <div className="row mt-3">
                        <div className="offset-md-4 col-md-4">
                          <div className="form-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input custom-file-label"
                                id="imgPerfilAlta"
                                name="imgPerfilAlta"
                                onChange={e => this.handleChangeFile(e)}
                                autoComplete="off"
                                accept="image/*"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="imgPerfilAlta"
                              >
                                Escoge una imagen
                              </label>
                            </div>
                          </div>

                          {btnGuardar}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="offset-md-3 col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <span>{this.state.nombrePerfil}</span>
                        </div>

                        <div className="col-md-6">
                          <span>{this.state.correoPerfil}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="collapse-parent">
          <div className="card mt-3">
            <div className="card-header ">
              <h3 className="card-title">
                <a
                  href="#cambio-pass"
                  data-toggle="collapse"
                  className="perfil-link"
                >
                  Cambio de Contraseña{" "}
                  <i
                    className="fas fa-sort-down"
                    style={{ fontSize: "1.2rem" }}
                  />
                </a>
              </h3>
            </div>

            <div
              className="card-body collapse"
              id="cambio-pass"
              data-parent="#collapse-parent"
            >
              <div className="container-fluid">
                <div className="row mt-4">
                  <form
                    action="#"
                    onSubmit={e => this.validarCambioPass(e)}
                    style={{ width: "100%" }}
                  >
                    <div className="col-md-12">
                      <div className="row">
                        <div className="offset-2 col-8 ">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-3">
                                <label htmlFor="" className="mt-2">
                                  Contraseña actual:
                                </label>
                              </div>

                              <div className="col-md-9">
                                <input
                                  type="password"
                                  className="form-control"
                                  name="contraseniaActual"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.contraseniaActual}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="offset-2 col-8 ">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-3">
                                <label htmlFor="" className="mt-2">
                                  Contraseña nueva:
                                </label>
                              </div>

                              <div className="col-md-9">
                                <input
                                  type="password"
                                  className="form-control"
                                  name="contraseniaNueva"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.contraseniaNueva}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="offset-2 col-8 ">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-3">
                                <label htmlFor="" className="mt-2">
                                  Repetir contraseña nueva:
                                </label>
                              </div>

                              <div className="col-md-9">
                                <input
                                  type="password"
                                  className="form-control"
                                  name="contraseniaNuevaValidacion"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.contraseniaNuevaValidacion}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 text-center">
                        <button className="btn btn-primary">
                          Cambiar Contraseña
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-header ">
              <h3 className="card-title">
                <a
                  href="#cambio-correo"
                  data-toggle="collapse"
                  className="perfil-link"
                >
                  Cambio de Correo Electrónico
                  <i
                    className="fas fa-sort-down"
                    style={{ fontSize: "1.2rem" }}
                  />
                </a>
              </h3>
            </div>

            <div
              className="card-body collapse"
              id="cambio-correo"
              data-parent="#collapse-parent"
            >
              <div className="container-fluid">
                <div className="row mt-4">
                  <form
                    action="#"
                    onSubmit={e => this.validarCambioCorreo(e)}
                    style={{ width: "100%" }}
                  >
                    <div className="col-md-12">
                      <div className="row">
                        <div className="offset-2 col-8 ">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-3">
                                <label htmlFor="" className="mt-2">
                                  Correo electrónico actual:
                                </label>
                              </div>

                              <div className="col-md-9">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="emailActual"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.emailActual}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="offset-2 col-8 ">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-3">
                                <label htmlFor="" className="mt-2">
                                  Correo electrónico nuevo:
                                </label>
                              </div>

                              <div className="col-md-9">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="emailNuevo"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.emailNuevo}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="offset-2 col-8 ">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-3">
                                <label htmlFor="" className="mt-2">
                                  Repetir correo electrónico nuevo:
                                </label>
                              </div>

                              <div className="col-md-9">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="emailNuevoValidacion"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.emailNuevoValidacion}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 text-center">
                        <button className="btn btn-primary">
                          Cambiar Correo
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-header ">
              <h3 className="card-title">
                <a
                  href="#cambio-tel"
                  data-toggle="collapse"
                  className="perfil-link"
                >
                  Cambio de Teléfono
                  <i
                    className="fas fa-sort-down"
                    style={{ fontSize: "1.2rem" }}
                  />
                </a>
              </h3>
            </div>

            <div
              className="card-body collapse"
              id="cambio-tel"
              data-parent="#collapse-parent"
            >
              <div className="container-fluid">
                <div className="row mt-4">
                  <div
                    className="col-md-6"
                    style={{ borderRight: "1px solid #53b5ab" }}
                  >
                    <form
                      action="#"
                      onSubmit={e => this.validarCambioTel(e)}
                      style={{ width: "100%" }}
                    >
                      <div className="row">
                        <div className="offset-1 col-10">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-3">
                                <label htmlFor="" className="mt-2">
                                  Teléfono actual:
                                </label>
                              </div>

                              <div className="col-md-9">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="telActual"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.telActual}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className=" offset-1 col-10 ">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-3">
                                <label htmlFor="" className="mt-2">
                                  Teléfono nuevo:
                                </label>
                              </div>

                              <div className="col-md-9">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="telNuevo"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.telNuevo}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className=" offset-1 col-10 ">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-3">
                                <label htmlFor="" className="mt-2">
                                  Repetir teléfono nuevo:
                                </label>
                              </div>

                              <div className="col-md-9">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="telNuevoValidacion"
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.telNuevoValidacion}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 text-center">
                        <button className="btn btn-primary">
                          Cambiar Teléfono
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <div className="offset-md-1 col-md-10">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Código SMS"
                        />
                      </div>

                      <div className="d-flex justify-content-end">
                        <button className="btn btn-primary">
                          Confirmar SMS
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Perfil;
