import React, { Component } from "react";
import "../../assets/css/login/login.css";
import logo from "../../assets/img/banner.jpg";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import Loader from "../Layout/loader";

// Funciones
import { LoginUser, guardarUsuarioEnCookies } from "../../functions/login";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: "",
      password: "",
      isLoading: false,
      hasError: false,
      error: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentWillMount() {}

  async validarUsuario(event) {
    event.preventDefault();

    let usuarioInput = this.state.usuario;
    let passInput = this.state.password;

    if (usuarioInput === "" || passInput === "") {
      alert("Favor de completar los campos de inicio de sesión.");
      return false;
    }

    this.setState({
      isLoading: true
    });

    let response = await LoginUser(usuarioInput, passInput);

    if (response["status"] !== 200) {
      this.setState({
        isLoading: false
      });

      this.setState({
        usuario: "",
        password: "",
        hasError: true,
        error: `${response["data"]}`
      });

      return false;
    }

    guardarUsuarioEnCookies(response["data"]);
    this.props.history.push("/Dashboard");
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      let hasErrorDisplay = "";

      if (!this.state.hasError) {
        hasErrorDisplay = "d-none";
      }

      return (
        <form
          action="#"
          id="form-input"
          method="POST"
          onSubmit={e => this.validarUsuario(e)}
        >
          <div className="form-group">
            <img src={logo} alt="SyBHS Logo" className="logo-login" />
          </div>

          <div className={`form-group ${hasErrorDisplay}`}>
            <div className="alert alert-danger" role="alert">
              <strong>¡Error!</strong> {this.state.error}.
            </div>
          </div>

          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fas fa-user" />
                </span>
              </div>

              <input
                type="text"
                className="form-control"
                name="usuario"
                placeholder="Usuario"
                onChange={e => this.handleChange(e)}
                value={this.state.usuario}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fas fa-key" />
                </span>
              </div>

              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Contraseña"
                onChange={e => this.handleChange(e)}
                value={this.state.password}
              />
            </div>
          </div>

          <button className="btn btn-primary w-100">Iniciar sesión</button>

          <div className="m-3">
            <Link
              to={`${process.env.PUBLIC_URL}/forgot-password`}
              style={{ color: "#495057" }}
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
        </form>
      );
    }
  }
}

export default withRouter(Login);
