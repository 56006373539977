import React, { Component } from "react";
import Cookie from "js-cookie";
import NoUser from "../../assets/img/NoUser.jpg";
import "../../assets/css/perfil/perfil.css";
import { cambiarContrasenia } from "../../functions/Perfil/perfil";
import { logout } from "../../functions/login";

import Swal from "sweetalert2";

class MiEmpresa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      imgEmpresa: "",
      nombreEmpresa: "",
      dirEmpresa: ""
    };
  }

  componentDidMount() {
    let usuario = JSON.parse(Cookie.get("usuario"));

    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    this.setState({
      imgEmpresa: window.sessionStorage.getItem("businessLogo"),
      nombreEmpresa: usuario["businessName"],
      dirEmpresa: usuario["bussinesAddress"]
    });

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h3>
                    <img
                      src={this.state.imgEmpresa}
                      style={{ maxWidth: "90px" }}
                    />
                    {this.state.nombreEmpresa}
                  </h3>
                </div>
              </div>

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="embed-responsive embed-responsive-16by9"
                      style={{ maxHeight: "500px" }}
                    >
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230186.95356222498!2d-100.44318032036307!3d25.6490376398421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86629531b437f8f5%3A0xa3d3d3ca6ac89894!2sMonterrey%2C+N.L.!5e0!3m2!1ses-419!2smx!4v1553136346881"
                        width="600"
                        height="450"
                        frameBorder="0"
                        style={{ border: "0" }}
                        allowFullScreen
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <p className="lead m-0">Direccion: </p>
                      <p className="m-0">{this.state.dirEmpresa}</p>
                    </div>

                    <div>
                      <p className="lead m-0">Teléfono: </p>
                      <p className="m-0" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4 ">
                <div className="col-md-12 d-none text-center">
                  <button className="btn btn-primary">
                    Contactar Administrador
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MiEmpresa;
