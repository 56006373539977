import React, { Component } from "react";
import "../../assets/css/login/login.css";
import logo from "../../assets/img/banner.jpg";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

// Funciones
import { changePass } from "../../functions/login";

// Sweetalert
import Swal from "sweetalert2";

class RestaurarPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nuevaPass: "",
      nuevaPassValidate: "",
      id: this.props.match.params.id,
      token: this.props.match.params.token
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  async validarNewPass(e) {
    e.preventDefault();

    if (this.state.nuevaPass === "" || this.state.nuevaPassValidate === "") {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Ninguno de los campos pueden estar vacíos."
      });

      return false;
    }

    if (this.state.nuevaPass !== this.state.nuevaPassValidate) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Las contraseñas no coinciden."
      });

      return false;
    }

    let changePassResponse = await changePass(
      this.state.id,
      this.state.nuevaPass,
      this.state.token
    );

    console.log(changePassResponse);
  }

  render() {
    return (
      <form
        action="#"
        id="form-input"
        method="POST"
        onSubmit={e => this.validarNewPass(e)}
      >
        <div className="form-group">
          <img src={logo} alt="SyBHS Logo" className="logo-login" />
        </div>

        <div className="form-group">
          <input
            type="password"
            className="form-control"
            name="nuevaPass"
            placeholder="Nueva Contraseña"
            onChange={e => this.handleChange(e)}
            value={this.state.nuevaPass}
            autoComplete="off"
          />
        </div>

        <div className="form-group">
          <input
            type="password"
            className="form-control"
            name="nuevaPassValidate"
            placeholder="Nueva Contraseña"
            onChange={e => this.handleChange(e)}
            value={this.state.nuevaPassValidate}
            autoComplete="off"
          />
        </div>

        <button className="btn btn-primary">Cambiar Contraseña</button>

        <div className="m-3">
          <Link to={`${process.env.PUBLIC_URL}/`} style={{ color: "#495057" }}>
            Regresar a Iniciar Sesión
          </Link>
        </div>
      </form>
    );
  }
}

export default withRouter(RestaurarPass);
