import React, { Component } from "react";
import { withRouter } from "react-router";
import { logout } from "../../../functions/login";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";
import Descargas from "../../Layout/descargas";

// Funciones
import { getAllLogs } from "../../../functions/Operacion/registros";

// Datatables
import { Tbl } from "../../Layout/table";
import { formatoFecha } from "../../../functions/Produccion/formato-fecha";

class Registros extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      dataSet: [],
      id: "id",
      columns: [
        {
          dataField: "conductor",
          text: "Conductor",
          sort: true
        },
        {
          dataField: "embarque",
          text: "Embarque",
          sort: true
        },
        {
          dataField: "estatus",
          text: "Estatus",
          sort: true
        },
        {
          dataField: "fechaInicio",
          text: "Fecha Inicio",
          formatter: formatoFecha,
          sort: true
        },
        {
          dataField: "fechaFin",
          text: "Fecha Fin",
          formatter: formatoFecha,
          sort: true
        },
        {
          dataField: "unidad",
          text: "Unidad",
          sort: true
        },
        {
          dataField: "actividadAuxiliar",
          text: "Actividad Auxiliar",
          sort: true
        },
        {
          dataField: "casoExcepción",
          text: "Caso de Excepción",
          sort: true
        },
        {
          dataField: "otro",
          text: "Otro",
          sort: true
        }
      ],
      isLoading: true
    };

    this.refreshLogs = this.refreshLogs.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    this.setState({
      dataSet: await this.obtenerLogs(),
      isLoading: false
    });

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  timeToLoad() {
    this.setState({
      timeLoad: this.state.timeLoad + 1
    });
  }

  async obtenerLogs() {
    let dataSetLogs = await getAllLogs(JSON.parse(Cookies.get("usuario")));

    console.log(dataSetLogs);

    if (dataSetLogs === 401) {
      logout();
      this.props.history.push("/Login");
      return false;
    }

    return dataSetLogs;
  }

  async refreshLogs(e) {
    this.setState({
      isLoading: true
    });

    this.setState({
      dataSet: await this.obtenerLogs(),
      isLoading: false
    });
  }

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Tbl
                id={this.state.id}
                columns={this.state.columns}
                data={this.state.dataSet}
                actualizar={this.refreshLogs}
              />

              {Object.entries(this.state.dataSet).length === 0 ? (
                ""
              ) : (
                <Descargas dataSet={this.state.dataSet} nombre="Registros" />
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body loader-inside">{info}</div>
      </div>
    );
  }
}

export default withRouter(Registros);
