import React, { Component } from "react";
import "../../assets/css/left-menu/left-menu.css";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
import { logout } from "../../functions/login";

// img
import logo from "../../assets/img/banner.jpg";
import banner from "../../assets/img/landing/banner.jpg";

// Menu Items
import Gestion from "../Layout/menu-items/gestion";
import Administracion from "../Layout/menu-items/administracion";
import Produccion from "../Layout/menu-items/produccion";
import Operacion from "../Layout/menu-items/operacion";
import AppErrors from "../Layout/menu-items/apperrors";
import Reportes from "../Layout/menu-items/reportes";
import Soporte from "../Layout/menu-items/soporte";

class LeftMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usuarioRol: JSON.parse(Cookie.get("usuario"))["range"],
      imagenMenu: banner
    };
  }

  toggleMenu(e) {
    let el = document.getElementById("sidebar");
    let flecha = document.getElementById("toggleMenu");
    let logoMenu = document.getElementById("img-left-menu");

    console.log(`Flecha ${flecha}`);
    if (el.classList.contains("active")) {
      el.classList.remove("active");
      flecha.classList.add("fa-caret-left");
      flecha.classList.remove("fa-caret-right");

      logoMenu.classList.add("logo-menu-unactive");

      this.setState({
        imagenMenu: banner
      });
    } else {
      el.classList.add("active");
      flecha.classList.remove("fa-caret-left");
      flecha.classList.add("fa-caret-right");

      logoMenu.classList.remove("logo-menu-unactive");

      this.setState({
        imagenMenu: logo
      });
    }
  }

  menuPorRol() {
    switch (this.state.usuarioRol) {
      // Dispatcher
      case 1:
        return (
          <div>
            <div className="text-right">
              <button
                type="button"
                id="sidebarCollapse"
                className="btn btn-info"
                onClick={() => this.toggleMenu()}
              >
                <span>
                  <i id="toggleMenu" className="fas fa-caret-left toggleMenu" />
                </span>
              </button>
            </div>

            <div className="col-12 text-center">
              <Link to="/Dashboard">
                <img
                  id="img-left-menu"
                  className="img-logo logo-menu-unactive mt-3"
                  src={this.state.imagenMenu}
                  alt="SyBHS"
                />
              </Link>
            </div>

            <ul className="list-unstyled components" id="collapse-menu">
              <Produccion />
              <Operacion />
              <Reportes />
            </ul>
          </div>
        );

      // Managers
      case 2:
        return (
          <div>
            <div className="text-right">
              <button
                type="button"
                id="sidebarCollapse"
                className="btn btn-info"
                onClick={() => this.toggleMenu()}
              >
                <span>
                  <i id="toggleMenu" className="fas fa-caret-left toggleMenu" />
                </span>
              </button>
            </div>

            <div className="col-12 text-center">
              <Link to="/Dashboard">
                <img
                  id="img-left-menu"
                  className=" img-logo logo-menu-unactive mt-3"
                  src={this.state.imagenMenu}
                  alt="SyBHS"
                />
              </Link>
            </div>

            <ul className="list-unstyled components" id="collapse-menu">
              <Administracion />
              <Produccion />
              <Operacion />
              <Reportes />
            </ul>
          </div>
        );

      // Admin
      case (3, 4):
        return (
          <div>
            <div className="text-right">
              <button
                type="button"
                id="sidebarCollapse"
                className="btn btn-info"
                onClick={() => this.toggleMenu()}
              >
                <span>
                  <i id="toggleMenu" className="fas fa-caret-left toggleMenu" />
                </span>
              </button>
            </div>

            <div className="col-12 text-center">
              <Link to="/Dashboard">
                <img
                  id="img-left-menu"
                  className=" img-logo logo-menu-unactive mt-3"
                  src={this.state.imagenMenu}
                  alt="SyBHS"
                />
              </Link>
            </div>

            <ul className="list-unstyled components" id="collapse-menu">
              <Gestion />
              <Administracion />
              <Produccion />
              <Operacion />
              <Reportes />
            </ul>
          </div>
        );

      // Dev
      case 5:
        return (
          <div>
            <div className="text-right">
              <button
                type="button"
                id="sidebarCollapse"
                className="btn btn-info"
                onClick={() => this.toggleMenu()}
              >
                <span>
                  <i id="toggleMenu" className="fas fa-caret-left toggleMenu" />
                </span>
              </button>
            </div>

            <div className="col-12 text-center">
              <Link to="/Dashboard">
                <img
                  id="img-left-menu"
                  className=" img-logo logo-menu-unactive mt-3"
                  src={this.state.imagenMenu}
                  alt="SyBHS"
                />
              </Link>
            </div>

            <ul className="list-unstyled components" id="collapse-menu">
              <AppErrors />
              <Gestion />
              <Administracion />
              <Produccion />
              <Operacion />
              <Reportes />
            </ul>
          </div>
        );

      // Driver
      case 6:
        return (
          <div>
            <div className="text-right">
              <button
                type="button"
                id="sidebarCollapse"
                className="btn btn-info"
                onClick={e => this.toggleMenu(e)}
              >
                <span>
                  <i id="toggleMenu" className="fas fa-caret-left toggleMenu" />
                </span>
              </button>
            </div>

            <div className="col-12 text-center">
              <Link to="/Dashboard">
                <img
                  id="img-left-menu"
                  className=" img-logo logo-menu-unactive mt-3"
                  src={this.state.imagenMenu}
                  alt="SyBHS"
                />
              </Link>
            </div>

            <ul className="list-unstyled components" id="collapse-menu">
              <Operacion />
              <Reportes />
            </ul>
          </div>
        );

      default:
        logout();
        this.props.history.push("/Login");
        break;
    }
  }

  render() {
    let menuItems = this.menuPorRol();

    return (
      <nav id="sidebar" className="">
        {menuItems}

        <div>
          <ul className="list-unstyled components">
            <Soporte />
          </ul>
        </div>
      </nav>
    );
  }
}

export default LeftMenu;
