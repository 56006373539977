import axios from "axios";
import { uploadImagen } from "../formato-imagen";

export const getAllEmbarques = usuario => {
  let embarquesResponse = axios
    .get("https://sybhs.azurewebsites.net/Shipment", {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });

  return embarquesResponse;
};

export const getPdf = (usuario, idPdf) => {
  let pdfResponse = axios
    .get("https://sybhs.azurewebsites.net/Shipment/" + idPdf + "/pdf", {
      headers: {
        Authorization: usuario.token
      },
      responseType: "blob"
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });

  return pdfResponse;
};

export const registerEmbarque = async (usuario, objEmbarque) => {
  let urlFirmaResponsable = await uploadImagen(
    usuario,
    objEmbarque["firmaResponsableAlta"]
  );

  if (urlFirmaResponsable === 401 || urlFirmaResponsable === 400) {
    return 401;
  }

  let urlFirmaConductor = await uploadImagen(
    usuario,
    objEmbarque["firmaConductorAlta"]
  );

  if (urlFirmaConductor === 401 || urlFirmaConductor === 400) {
    return 401;
  }

  let data = {
    id: 0,
    business: objEmbarque["empresaAlta"],
    driver: objEmbarque["conductorAlta"],
    profile: objEmbarque["unidadAlta"],
    number: objEmbarque["numeroAlta"],
    departureTime: new Date(
      objEmbarque["fechaSalidaAlta"] +
        " " +
        objEmbarque["horaSalidaAlta"] +
        ":00"
    ).getTime(),
    isDelivered: false,
    serviceType: objEmbarque["tipoServicioAlta"],
    modality: objEmbarque["modalidadAlta"],
    departure: objEmbarque["lugarSalidaAlta"],
    arrival: objEmbarque["lugarLlegadaAlta"],
    intermediatePoints: objEmbarque["puntosIntAlta"],
    responsibleSignature: urlFirmaResponsable,
    driverSignature: urlFirmaConductor
  };

  return axios
    .post("https://sybhs.azurewebsites.net/Shipment", data, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export const getEmbarque = async (usuario, idEmbarque) => {
  return await axios
    .get("https://sybhs.azurewebsites.net/Shipment/" + idEmbarque, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export const editarEmbarque = async (usuario, objEmbarque) => {
  let urlFirmaResponsable,
    urlFirmaConductor = "";

  if (objEmbarque["isFileChanged"]) {
    urlFirmaResponsable = await uploadImagen(
      usuario,
      objEmbarque["firmaResponsableAlta"]
    );

    if (urlFirmaResponsable === 401 || urlFirmaResponsable === 400) {
      return 401;
    }

    urlFirmaConductor = await uploadImagen(
      usuario,
      objEmbarque["firmaConductorAlta"]
    );

    if (urlFirmaConductor === 401 || urlFirmaConductor === 400) {
      return 401;
    }
  } else {
    urlFirmaResponsable = objEmbarque["firmaResponsableAlta"];
    urlFirmaConductor = objEmbarque["firmaConductorAlta"];
  }

  let data = {
    id: objEmbarque["idEmbarque"],
    business: objEmbarque["empresaAlta"],
    driver: objEmbarque["conductorAlta"],
    profile: objEmbarque["unidadAlta"],
    number: objEmbarque["unidadAlta"],
    departureTime: new Date(
      objEmbarque["fechaSalidaAlta"] +
        " " +
        objEmbarque["horaSalidaAlta"] +
        ":00"
    ).getTime(),
    serviceType: objEmbarque["tipoServicioAlta"],
    modality: objEmbarque["modalidadAlta"],
    departure: objEmbarque["lugarSalidaAlta"],
    arrival: objEmbarque["lugarLlegadaAlta"],
    intermediatePoints: objEmbarque["puntosIntAlta"],
    responsibleSignature: urlFirmaResponsable,
    driverSignature: urlFirmaConductor
  };

  return axios
    .put("https://sybhs.azurewebsites.net/Shipment", data, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error;
    });
};

export const eliminarEmbarque = async (usuario, idEmbarque) => {
  return await axios
    .delete(
      "https://sybhs.azurewebsites.net/Shipment/",
      { id: idEmbarque },
      {
        headers: {
          Authorization: usuario.token
        }
      }
    )
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};
