import axios from "axios";
import { uploadImagen } from "../formato-imagen";

export const getAllUsuarios = usuario => {
  let usuariosResponse = axios
    .get("https://sybhs.azurewebsites.net/User", {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });

  return usuariosResponse;
};

export function formatConfirmado(cell, row) {
  if (cell === true) {
    return "Si";
  } else {
    return "No";
  }
}

export const registerUsuario = async (usuario, objUsuario) => {
  let urlImagen = await uploadImagen(usuario, objUsuario["fotoAlta"]);

  if (urlImagen === 401) {
    return 401;
  }

  let data = {
    user: {
      phoneNumber: objUsuario["telAlta"],
      picture: urlImagen,
      fullName: objUsuario["nombreCompletoAlta"],
      userName: objUsuario["nombreUsuarioAlta"],
      email: objUsuario["correoAlta"],
      business: objUsuario["empresaAlta"]
    },
    role: objUsuario["rolAlta"],
    password: objUsuario["contraseniaAlta"],
    confirmUrl: "https://www.sybhs.com/admin/"
  };

  return axios
    .post("https://sybhs.azurewebsites.net/user", data, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export const getUsuario = async (usuario, idUsuario) => {
  return await axios
    .get("https://sybhs.azurewebsites.net/User/" + idUsuario, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};
