import Axios from "axios";

export const cambiarContrasenia = (
  usuario,
  contraseniaActual,
  contraseniaNueva
) => {
  let changePassResponse = Axios.post(
    "https://sybhs.azurewebsites.net/change",
    {
      currentPassword: contraseniaActual,
      newPassword: contraseniaNueva
    },
    {
      headers: {
        Authorization: usuario.token
      }
    }
  )
    .then(function(response) {
      return response["status"];
    })
    .catch(function(error) {
      return error.response["status"];
    });

  return changePassResponse;
};
