import React, { Component } from "react";
import { withRouter } from "react-router";
import Cookies from "js-cookie";
import Loader from "../../modules/Layout/loader";

//
import Counter from "../Varios/counter";

// Sweetalert2
import Swal from "sweetalert2";

class Contactanos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      isLoading: false,
      contactoMensaje: ""
    };
  }

  componentDidMount() {
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  formContacto = e => {
    e.preventDefault();

    if (this.state.contactoMensaje === "") {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "El campo de mensaje no puede estar vacío."
      });

      return false;
    }
  };

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div>
          <Counter />

          <div className="div-title mt-3">
            <p className="lead">
              <strong>
                ¿Tienes algún comentario y/o sugerencia? ¡Escríbenos!
              </strong>
            </p>
          </div>

          <form
            action="#"
            method="post"
            id="form-contacto"
            className="mt-4"
            onSubmit={e => this.formContacto(e)}
          >
            <div className="form-group">
              <textarea
                style={{ resize: "none" }}
                name="contactoMensaje"
                cols="30"
                rows="10"
                className="form-control"
                placeholder="Escribe tus dudas o sugerencias aquí..."
                onChange={e => this.handleChange(e)}
                value={this.state.contactoMensaje}
              />
            </div>

            <div className="d-flex justify-content-end">
              <button className="btn btn-primary">Enviar</button>
            </div>
          </form>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-md-12">{info}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Contactanos);
