import React, { Component } from "react";
import { Link } from "react-router-dom";

class Produccion extends Component {
  render() {
    return (
      <li className="">
        <a
          href="#produccion"
          data-toggle="collapse"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          <i className="fas fa-cubes" />
          <span className="menu-title">Producción</span>
        </a>
        <ul
          className="collapse list-unstyled main-menu"
          data-parent="#sidebar"
          id="produccion"
        >
          <li className="nav-item">
            <Link className="nav-link" to="/Unidades">
              Unidades
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Embarques">
              Embarques
            </Link>
          </li>
        </ul>

        <div className="active-submenu">
          <ul className="list-unstyled list-not-active">
            <li className="nav-item">
              <p className="m-1">
                <strong>Producción</strong>
              </p>

              <hr className="m-1" />
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/Unidades">
                Unidades
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Embarques">
                Embarques
              </Link>
            </li>
          </ul>
        </div>
      </li>
    );
  }
}

export default Produccion;
