import React, { Component } from "react";
import Loader from "../Layout/loader";

class ConfirmarSMS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      smsCode: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div>
          <div className="form-group">
            <label htmlFor="sms-confirmar" className="lead">
              <strong>
                Por favor ingresa el código SMS que llegó a tu celular.
              </strong>
            </label>

            <input
              type="text"
              className="form-control"
              name="smsCode"
              value={this.state.smsCode}
              onChange={e => this.handleChange(e)}
            />
          </div>

          <div className="d-flex justify-content-end">
            <button className="btn btn-primary">Confirmar</button>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-md-12">{info}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmarSMS;
