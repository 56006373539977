import React, { Component } from "react";
import Cookie from "js-cookie";
import Counter from "../Varios/counter";
import Loader from "../Layout/loader";

class Configuracion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifCorreo: "",
      notifSMS: ""
    };
  }

  componentDidMount() {
    let usuario = JSON.parse(Cookie.get("usuario"));
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div>
          <Counter />

          <div className="container-fluid mt-3">
            <div className="row mb-5">
              <div className="col-md-12">
                <p className="lead">
                  <strong>Gráficas del Dashboard</strong>
                </p>
              </div>

              <div className="col-md-12 ">
                <div className="row">
                  <div className="col-md-4">
                    <div className="notifiacion mb-2">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="embarquesCliente"
                          value={this.state.embarquesCliente}
                        />
                        <label
                          class="custom-control-label"
                          for="embarquesCliente"
                        >
                          Embarques por Cliente
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="notifiacion mb-2">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="Violaciones"
                          value={this.state.notifSMS}
                        />
                        <label class="custom-control-label" for="Violaciones">
                          Violaciones
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="notifiacion mb-2">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="Violaciones"
                          value={this.state.notifSMS}
                        />
                        <label class="custom-control-label" for="Violaciones">
                          Embarques por Lugar
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="notifiacion mb-2">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="Violaciones"
                          value={this.state.notifSMS}
                        />
                        <label class="custom-control-label" for="Violaciones">
                          Conducta de Conductores
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="notifiacion mb-2">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="Violaciones"
                          value={this.state.notifSMS}
                        />
                        <label class="custom-control-label" for="Violaciones">
                          Violaciones por Cliente
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="notifiacion mb-2">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="Violaciones"
                          value={this.state.notifSMS}
                        />
                        <label class="custom-control-label" for="Violaciones">
                          Embarques por Lugar
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p className="lead">
                  <strong>Notificaciones</strong>
                </p>
              </div>

              <div className="col-md-12 ">
                <div className="row">
                  <div className="col-md-4">
                    <div className="notifiacion mb-2">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="notif-correo"
                          value={this.state.notifCorreo}
                        />
                        <label class="custom-control-label" for="notif-correo">
                          Notificaciones por Correo
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="notifiacion mb-2">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="notif-SMS"
                          value={this.state.notifSMS}
                        />
                        <label class="custom-control-label" for="notif-SMS">
                          Notificaciones por SMS
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-md-12">{info}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Configuracion;
