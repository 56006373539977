import React, { Component } from "react";
import { withRouter } from "react-router";
import { logout } from "../../../functions/login";
import {
  getAllEmbarques,
  getPdf
} from "../../../functions/Produccion/embarques";
import Cookies from "js-cookie";
import Descargas from "../../Layout/descargas";

import Loader from "../../Layout/loader";

// Datatables
import { Tbl } from "../../Layout/table";

// Funciones
import { formatoFecha } from "../../../functions/Produccion/formato-fecha";

class Embarques extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      dataSet: [],
      id: "id",
      columns: [
        {
          dataField: "key",
          text: "PDF",
          formatter: this.formatImgPdf,
          sort: false,
          classes: "text-center"
        },
        {
          dataField: "folio",
          text: "Folio",
          sort: true
        },
        {
          dataField: "empresa",
          text: "Empresa",
          sort: true
        },
        {
          dataField: "conductor",
          text: "Conductor",
          sort: true
        },
        {
          dataField: "saleDe",
          text: "Sale De",
          sort: true
        },
        {
          dataField: "fechaSalida",
          text: "Fecha de Salida",
          formatter: formatoFecha,
          sort: true
        },
        {
          dataField: "lLegaA",
          text: "Llega a",
          sort: true
        },
        {
          dataField: "fechaLLegada",
          text: "Fecha de llegada",
          formatter: formatoFecha,
          sort: true
        },
        {
          dataField: "entregado",
          text: "Entregado",
          sort: true
        },
        {
          dataField: "tipoCarga",
          text: "Tipo de Carga",
          sort: true
        }
      ],
      rowEvents: {
        onClick: (e, row, rowIndex) => {
          this.props.history.push(`/Editar-Embarque/${row["id"]}`);
        }
      },
      isLoading: true
    };

    this.refreshEmbarques = this.refreshEmbarques.bind(this);
  }

  formatImgPdf(cell, row) {
    return (
      <button
        style={{
          border: "none",
          background: "none"
        }}
        onClick={async e => {
          e.stopPropagation();

          let responsePdf = await getPdf(
            JSON.parse(Cookies.get("usuario")),
            row["id"]
          );

          if (responsePdf === 401) {
            logout();
            this.props.history.push("/Login");
            return false;
          }

          let a = document.createElement("a");
          let fileURL = URL.createObjectURL(responsePdf);
          a.href = fileURL;
          a.target = "_blank";
          a.click();
          window.URL.revokeObjectURL(fileURL);
        }}
      >
        <i className="fas fa-file-pdf fa-3x" />
        <p className="m-0">PDF</p>
      </button>
    );
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    this.setState({
      dataSet: await this.obtenerEmbarques(),
      isLoading: false
    });

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  timeToLoad() {
    this.setState({
      timeLoad: this.state.timeLoad + 1
    });
  }

  async obtenerEmbarques() {
    let dataSetEmbarques = await getAllEmbarques(
      JSON.parse(Cookies.get("usuario"))
    );

    if (dataSetEmbarques === 401) {
      logout();
      this.props.history.push("/Login");
      return false;
    }

    return dataSetEmbarques;
  }

  async refreshEmbarques(e) {
    this.setState({
      isLoading: true
    });

    this.setState({
      dataSet: await this.obtenerEmbarques(),
      isLoading: false
    });
  }

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Tbl
                id={this.state.id}
                columns={this.state.columns}
                data={this.state.dataSet}
                rowEvents={this.state.rowEvents}
                nuevoTexto="Nuevo Embarque"
                nuevoLink="Alta-Embarque"
                actualizar={this.refreshEmbarques}
              />

              {Object.entries(this.state.dataSet).length === 0 ? (
                ""
              ) : (
                <Descargas dataSet={this.state.dataSet} nombre="Embarques" />
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body loader-inside">{info}</div>
      </div>
    );
  }
}

export default withRouter(Embarques);
