import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import Layout from "./layout";

class NotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false
    };
  }

  componentDidMount() {}

  render() {
    if (this.state.loggedIn) {
      return (
        <Layout>
          <div className="p-3 text-center">
            <h1 className="display-4">Oops!</h1>
            <p className="lead">
              {" "}
              La página que buscas no se encuentra aquí. Por favor, regresa al{" "}
              <Link to="/Dashboard">Dashboard</Link>
            </p>
          </div>
        </Layout>
      );
    } else {
      return (
        <div className="p-3 text-center">
          <h1 className="display-4">Oops!</h1>
          <p className="lead">
            La página que buscas no se encuentra aquí. Por favor, ingresa al
            sistema <Link to={`${process.env.PUBLIC_URL}/`}>Aquí</Link>
          </p>
        </div>
      );
    }
  }
}

export default withRouter(NotFound);
