import Cookies from "js-cookie";
import axios from "axios";

// Modelo Usuario
import user from "../models/user/user";

export const LoginUser = async (user, pass) => {
  let respuesta = await axios
    .post("https://sybhs.azurewebsites.net/login", {
      userName: user,
      password: pass
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });

  return respuesta;
};

export const guardarUsuarioEnCookies = response => {
  if (response["user"]["picture"] === null) {
    response["user"]["picture"] = "";
  }

  let usuario = new user(
    response["user"]["name"],
    response["user"]["name"],
    response["user"]["email"],
    response["role"],
    response["token"],
    response["business"]["id"],
    response["business"]["name"],
    response["business"]["address"],
    response["user"]["picture"].length > 600
      ? null
      : response["user"]["picture"]
  );

  Cookies.remove("usuario");
  Cookies.set("usuario", usuario, { expires: 1 });
  window.sessionStorage.setItem("businessLogo", response["business"]["logo"]);

  return true;
};

export const logout = () => {
  let usuarioCookie = Cookies.get("usuario");

  axios
    .post("https://sybhs.azurewebsites.net/logout", {
      headers: {
        Authorization: (usuarioCookie = JSON.parse(usuarioCookie).token)
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response;
    });

  Cookies.remove("usuario");
  window.sessionStorage.removeItem("businessLogo");
};

export const resetPassword = email => {
  let data = {
    email: email,
    resetUrl: "https://www.sybhs.com/admin/reset"
  };

  axios
    .post("https://sybhs.azurewebsites.net/forgot", data)
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response;
    });
};

export const changePass = (id, password, token) => {
  let data = {
    id: id,
    password: password,
    token: token
  };

  axios
    .post("https://sybhs.azurewebsites.net/reset", data)
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response;
    });
};
