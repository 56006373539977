import axios from "axios";
import { uploadImagen } from "../formato-imagen";

export function formatReglas(cell, row) {
  switch (cell) {
    case 1:
      return "Cargo";

    case "":
      return "Pasajero";

    default:
      return "Desconocido";
  }
}

export const getAllConductores = usuario => {
  let conductoresResponse = axios
    .get("https://sybhs.azurewebsites.net/Driver", {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });

  return conductoresResponse;
};

export const registerConductor = async (usuario, objConductor) => {
  let urlImagen = await uploadImagen(usuario, objConductor["fotoAlta"]);

  if (urlImagen === 401) {
    return 401;
  }

  let data = {
    driver: {
      user: {
        business: objConductor["empresaAlta"],
        fullName: objConductor["nombreCompletoAlta"],
        email: objConductor["correoAlta"],
        userName: objConductor["nombreUsuarioAlta"],
        picture: urlImagen
      },

      licenseNumber: objConductor["numeroLicenciaAlta"],
      licenseDate: new Date(objConductor["fechaLicenciaAlta"]).getTime(),
      isAppCreated: false,
      ruleSetType: objConductor["rulsetAlta"]
    },
    password: objConductor["contraseniaAlta"]
  };

  return axios
    .post("https://sybhs.azurewebsites.net/Driver", data, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export const getConductor = async (usuario, idConductor) => {
  return await axios
    .get("https://sybhs.azurewebsites.net/Driver/" + idConductor, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export const editarConductor = async (usuario, objConductor) => {
  let urlImagen = "";

  if (objConductor["isFotoChanged"]) {
    urlImagen = await uploadImagen(usuario, objConductor["fotoAlta"]);

    if (urlImagen === 401) {
      return 401;
    }
  } else {
    urlImagen = objConductor["fotoAlta"];
  }

  let data = {
    id: objConductor["idConductor"],
    user: {
      picture: urlImagen,
      fullName: objConductor["nombreCompletoAlta"],
      userName: objConductor["nombreUsuarioAlta"],
      email: objConductor["correoAlta"],
      business: objConductor["empresaAlta"]
    },
    licenseNumber: objConductor["numeroLicenciaAlta"],
    licenseDate: new Date(objConductor["fechaLicenciaAlta"]).getTime(),
    ruleSetType: objConductor["rulsetAlta"]
  };

  return axios
    .put("https://sybhs.azurewebsites.net/Driver", data, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export const eliminarConductor = async (usuario, idConductor) => {
  return await axios
    .delete(
      "https://sybhs.azurewebsites.net/Business/",
      { id: idConductor },
      {
        headers: {
          Authorization: usuario.token
        }
      }
    )
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};
