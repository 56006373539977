import React, { Component } from "react";
import "../../assets/css/loader/loader.css";
import Flecha from "../../assets/img/flechita.png";

class Loader extends Component {
  render() {
    return (
      <div className="text-center loader-div">
        <img src={Flecha} style={{ width: "50px" }} />
        <div className="sk-folding-cube">
          <div className="sk-cube1 sk-cube" />
          <div className="sk-cube2 sk-cube" />
          <div className="sk-cube4 sk-cube" />
          <div className="sk-cube3 sk-cube" />
        </div>
      </div>
    );
  }
}

export default Loader;
