import axios from "axios";

export const getAllLugares = async usuario => {
  return await axios
    .get("https://sybhs.azurewebsites.net/AuthPlace", {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error;
    });
};

export const registerLugar = async (usuario, objLugar) => {
  let data = {
    business: objLugar["empresaAlta"],
    address: objLugar["direccionAlta"],
    latitude: objLugar["latAlta"],
    longitude: objLugar["lonAlta"]
  };

  return axios
    .post("https://sybhs.azurewebsites.net/AuthPlace", data, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      if (error.response) {
        return error.response["status"];
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return "error";
      }
    });
};
