import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../../../functions/login";
import { editarEmpresa, getEmpresa } from "../../../functions/Gestion/empresas";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";

// Sweetalert2
import Swal from "sweetalert2";

class EmpresaEdita extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      isEditing: false,
      isLoading: true,
      idEmpresa: "",
      nombreAlta: "",
      direccionAlta: "",
      logoAlta: "",
      logoPreview: "",
      isLogoChanged: false
    };
  }

  async componentDidMount() {
    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    let responseEmpresa = await getEmpresa(
      JSON.parse(Cookies.get("usuario")),
      this.props.match.params.id
    );

    this.setState({
      isLoading: false,
      idEmpresa: responseEmpresa["id"],
      nombreAlta: responseEmpresa["name"],
      direccionAlta: responseEmpresa["address"],
      logoPreview: responseEmpresa["logo"]
    });

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  timeToLoad() {
    this.setState({
      timeLoad: this.state.timeLoad + 1
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeFile = async e => {
    let file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        logoAlta: file,
        logoPreview: reader.result,
        isLogoChanged: true
      });
    };
  };

  async validarEmpresa(e) {
    e.preventDefault();

    let nombreEmpresa = this.state.nombreAlta;
    let direccionEmpresa = this.state.direccionAlta;
    let logoEmpresaFile = this.state.logoAlta;
    let logoEmpresaURL = this.state.logoPreview;
    let isLogoChanged = this.state.isLogoChanged;
    let idEmpresa = this.state.idEmpresa;

    if (nombreEmpresa === "" || direccionEmpresa === "") {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Favor de completar los campos de edición de Empresa"
      });

      return false;
    }

    if (isLogoChanged) {
      if (logoEmpresaFile === "") {
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: "Favor de completar los campos de edición de Empresa"
        });

        return false;
      }
    }

    Swal.fire({
      title: "¿Estas seguro?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Si, editar!",
      cancelButtonText: "Cancelar"
    }).then(async result => {
      if (result.value) {
        this.setState({
          isLoading: true
        });

        let registroEmpresaResponse = await editarEmpresa(
          JSON.parse(Cookies.get("usuario")),
          nombreEmpresa,
          direccionEmpresa,
          logoEmpresaFile,
          logoEmpresaURL,
          isLogoChanged,
          idEmpresa
        );

        if (registroEmpresaResponse === 401) {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: "Se ha terminado tu sesión, por favor vuelve a reingresar."
          });

          logout();
          this.props.history.push("/Login");
          return false;
        }

        this.setState({
          isLoading: false,
          isEditing: false
        });

        if (registroEmpresaResponse === "") {
          Swal.fire({
            type: "success",
            title: "¡Éxito!",
            text: "La Empresa a sido editada exitosamente."
          });
        } else {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: "La Empresa no pudo ser editada, por favor intente de nuevo."
          });

          return false;
        }
      }
    });
  }

  async eliminarEmpresa(e) {
    e.preventDefault();

    Swal.fire({
      title: "¿Eliminar?",
      text: "Esta acción no podrá ser deshecha.",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Si, eliminar!",
      cancelButtonText: "Cancelar"
    }).then(async result => {
      if (result.value) {
        /* let eliminarEmpresaResponse = await eliminarEmpresa(
          JSON.parse(Cookies.get("usuario")),
          this.state.idEmpresa
        );

        // respuesta eliminar */
      }
    });
  }

  habilitarEdicion() {
    this.setState({
      isEditing: !this.state.isEditing
    });
  }

  render() {
    let info, imgPreview;

    if (this.state.logoPreview === "") {
      imgPreview = "";
    } else {
      imgPreview = (
        <img
          src={this.state.logoPreview}
          alt="Logo Preview"
          style={{ maxWidth: "250px" }}
        />
      );
    }

    if (this.state.isLoading) {
      info = (
        <div className="text-center w-100">
          <Loader />
        </div>
      );
    } else {
      info = (
        <div className="col-md-12">
          <div className="row">
            <div className="offset-3 col-6 ">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="" className="mt-2">
                      Nombre:
                    </label>
                  </div>

                  <div className="col-md-9">
                    <input
                      disabled={this.state.isEditing ? "" : "disabled"}
                      type="text"
                      className="form-control"
                      name="nombreAlta"
                      onChange={e => this.handleChange(e)}
                      value={this.state.nombreAlta}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="offset-3 col-6 ">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="" className="mt-2">
                      Dirección:
                    </label>
                  </div>

                  <div className="col-md-9">
                    <input
                      disabled={this.state.isEditing ? "" : "disabled"}
                      type="text"
                      className="form-control"
                      name="direccionAlta"
                      onChange={e => this.handleChange(e)}
                      value={this.state.direccionAlta}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="offset-3 col-6 ">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="" className="mt-2">
                      Logo:
                    </label>
                  </div>

                  <div className="col-md-9">
                    <div className="form-group">
                      <div className="custom-file">
                        <input
                          disabled={this.state.isEditing ? "" : "disabled"}
                          type="file"
                          className="custom-file-input custom-file-label"
                          id="logoAlta"
                          name="logoAlta"
                          onChange={e => this.handleChangeFile(e)}
                          autoComplete="off"
                          accept="image/*"
                        />
                        <label className="custom-file-label" htmlFor="logoAlta">
                          Escoge una imagen
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="offset-3 col-6 ">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="" className="mt-2">
                      Imagen:
                    </label>
                  </div>

                  <div className="col-md-9">{imgPreview}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`col-md-12 text-center ${
              this.state.isEditing ? "d-flex" : "d-none"
            } justify-content-between`}
          >
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => this.habilitarEdicion()}
            >
              Cancelar
            </button>

            <button type="submit" className="btn btn-primary">
              Confirmar
            </button>
          </div>

          <div
            className={`col-md-12 text-center ${
              this.state.isEditing ? "d-none" : "d-flex"
            } justify-content-between`}
            style={{ display: this.state.isEditing ? "none" : "flex" }}
          >
            <button
              type="button"
              className="btn btn-danger"
              onClick={e => this.eliminarEmpresa(e)}
            >
              Eliminar
            </button>

            <button
              type="button"
              onClick={() => this.habilitarEdicion()}
              className="btn btn-primary"
            >
              Editar
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <Link to={`${process.env.PUBLIC_URL}/Empresas`}>
                  <button className="btn btn-primary">Listado Empresas</button>
                </Link>
              </div>
            </div>

            <form
              className="mt-5"
              action="#"
              onSubmit={e => this.validarEmpresa(e)}
            >
              <div className="row">{info}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EmpresaEdita);
