import React, { Component } from "react";
import { withRouter } from "react-router";
import { logout } from "../../../functions/login";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";
import Descargas from "../../Layout/descargas";

// Funciones
import { getAllLugares } from "../../../functions/Administracion/authplaces";

// Datatables
import { Tbl } from "../../Layout/table";

class AuthPlaces extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      dataSet: [],
      id: "id",
      columns: [
        {
          dataField: "business",
          text: "Empresa",
          classes: "text-center",
          sort: false
        },
        {
          dataField: "address",
          text: "Dirección",
          classes: "text-center",
          sort: false
        }
      ],
      rowEvents: {
        onClick: (e, row, rowIndex) => {
          this.props.history.push(
            `${process.env.PUBLIC_URL}/Edita-Lugar/${row["id"]}`
          );
        }
      },
      isLoading: true
    };

    this.refreshLugares = this.refreshLugares.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    this.setState({
      //dataSet: await this.obtenerLugares(),
      isLoading: false
    });

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  timeToLoad() {
    this.setState({
      timeLoad: this.state.timeLoad + 1
    });
  }

  async obtenerLugares() {
    let dataSetLugares = await getAllLugares(
      JSON.parse(Cookies.get("usuario"))
    );

    if (dataSetLugares === 401) {
      logout();
      this.props.history.push("/Login");
      return false;
    }

    return dataSetLugares;
  }

  async refreshLugares(e) {
    this.setState({
      isLoading: true
    });

    this.setState({
      dataSet: await this.obtenerLugares(),
      isLoading: false
    });
  }

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Tbl
                id={this.state.id}
                columns={this.state.columns}
                data={this.state.dataSet}
                rowEvents={this.state.rowEvents}
                nuevoTexto="Nuevo Lugar Autorizado"
                nuevoLink="Alta-Lugar"
                actualizar={this.refreshLugares}
              />

              {Object.entries(this.state.dataSet).length === 0 ? (
                ""
              ) : (
                <Descargas
                  dataSet={this.state.dataSet}
                  nombre="LugaresAutorizados"
                />
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body loader-inside">{info}</div>
      </div>
    );
  }
}

export default withRouter(AuthPlaces);
