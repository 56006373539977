import axios from "axios";

export const getAllAppErrors = async usuario => {
  return await axios
    .get("https://sybhs.azurewebsites.net/AppError", {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export const getAppError = async (usuario, idAppError) => {
  return await axios
    .get("https://sybhs.azurewebsites.net/AppError/" + idAppError, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export function formatText(cell, row) {
  if (cell !== "") {
    if (cell.length <= 40) {
      return cell;
    } else {
      return cell.substring(0, 37) + "...";
    }
  } else {
    return "N/A";
  }
}
