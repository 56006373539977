import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../../../functions/login";
import { getAllConductores } from "../../../functions/Administracion/conductores";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";

// Empresas
import { getAllEmpresas } from "../../../functions/Gestion/empresas";

// Sweetalert2
import Swal from "sweetalert2";
import { getAllUnidades } from "../../../functions/Produccion/unidades";
import {
  getEmbarque,
  editarEmbarque,
  getPdf
} from "../../../functions/Produccion/embarques";

class EmbarqueEditar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      isEditing: false,
      isLoading: true,
      listadoEmpresas: "",
      listadoConductores: "",
      listadoUnidades: "",
      empresaAlta: "",
      conductorAlta: "",
      unidadAlta: "",
      numeroAlta: "",
      lugarSalidaAlta: "",
      fechaSalidaAlta: "",
      horaSalidaAlta: "",
      lugarLlegadaAlta: "",
      puntosIntAlta: "",
      modalidadAlta: "",
      tipoServicioAlta: "Federal",
      firmaResponsableAlta: "",
      firmaResponsablePreview: "",
      firmaConductorAlta: "",
      firmaConductorPreview: "",
      fileChanged: false,
      idEmbarque: "",
      urlPdf: ""
    };
  }

  async componentWillMount() {
    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    let listadoEmpresas = await getAllEmpresas(
      JSON.parse(Cookies.get("usuario"))
    );

    let listadoConductores = await getAllConductores(
      JSON.parse(Cookies.get("usuario"))
    );

    let listadoUnidades = await getAllUnidades(
      JSON.parse(Cookies.get("usuario"))
    );

    let infoEmbarque = await getEmbarque(
      JSON.parse(Cookies.get("usuario")),
      this.props.match.params.id
    );

    let responsePdf = await getPdf(
      JSON.parse(Cookies.get("usuario")),
      this.props.match.params.id
    );

    if (
      listadoEmpresas === 401 ||
      listadoConductores === 401 ||
      listadoUnidades === 401 ||
      infoEmbarque === 401 ||
      responsePdf === 401
    ) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    let fechaInfoEmbarque = new Date(infoEmbarque["departureTime"]);

    let fecha = fechaInfoEmbarque.toISOString().substr(0, 10);
    let hora =
      this.checkTime(fechaInfoEmbarque.getHours()) +
      ":" +
      this.checkTime(fechaInfoEmbarque.getMinutes());

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);

    // Creamos la url del formato a descargar
    let fileURL = URL.createObjectURL(responsePdf);

    this.setState({
      listadoEmpresas: listadoEmpresas,
      listadoUnidades: listadoUnidades,
      listadoConductores: listadoConductores,
      empresaAlta: infoEmbarque["business"],
      conductorAlta: infoEmbarque["driver"],
      unidadAlta: infoEmbarque["profile"],
      numeroAlta: infoEmbarque["number"],
      lugarSalidaAlta: infoEmbarque["departure"],
      fechaSalidaAlta: fecha,
      horaSalidaAlta: hora,
      lugarLlegadaAlta: infoEmbarque["arrival"],
      puntosIntAlta: infoEmbarque["intermediatePoints"],
      modalidadAlta: infoEmbarque["modality"],
      tipoServicioAlta: infoEmbarque["serviceType"],
      firmaResponsablePreview: infoEmbarque["responsibleSignature"],
      firmaResponsableAlta: infoEmbarque["responsibleSignature"],
      firmaConductorPreview: infoEmbarque["driverSignature"],
      firmaConductorAlta: infoEmbarque["driverSignature"],
      idEmbarque: infoEmbarque["id"],
      urlPdf: fileURL,
      isLoading: false
    });
  }

  timeToLoad() {
    this.setState({
      timeLoad: this.state.timeLoad + 1
    });
  }

  checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  async validarEmbarque(e) {
    e.preventDefault();

    let nuevoEmbarque = {
      empresaAlta: this.state.empresaAlta,
      conductorAlta: this.state.conductorAlta,
      unidadAlta: this.state.unidadAlta,
      numeroAlta: this.state.numeroAlta,
      fechaSalidaAlta: this.state.fechaSalidaAlta,
      lugarSalidaAlta: this.state.lugarSalidaAlta,
      horaSalidaAlta: this.state.horaSalidaAlta,
      lugarLlegadaAlta: this.state.lugarLlegadaAlta,
      puntosIntAlta: this.state.puntosIntAlta,
      tipoServicioAlta: this.state.tipoServicioAlta,
      modalidadAlta: this.state.modalidadAlta,
      firmaResponsableAlta: this.state.firmaResponsableAlta,
      firmaConductorAlta: this.state.firmaConductorAlta,
      idEmbarque: this.state.idEmbarque,
      isFileChanged: this.state.fileChanged
    };

    let isInputEmpty = Object.keys(nuevoEmbarque).some(function(keyName) {
      if (nuevoEmbarque[keyName] === "") {
        return true;
      }
    });

    if (isInputEmpty === true) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Favor de completar los campos de edición de Embarque"
      });

      return false;
    }

    Swal.fire({
      title: "¿Estas seguro?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Si, editar!",
      cancelButtonText: "Cancelar"
    }).then(async result => {
      if (result.value) {
        this.setState({
          isLoading: true
        });

        let registroEmbarqueResponse = await editarEmbarque(
          JSON.parse(Cookies.get("usuario")),
          nuevoEmbarque
        );

        if (registroEmbarqueResponse === 401) {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
          });

          logout();
          this.props.history.push("/Login");
          return false;
        }

        this.setState({
          isLoading: false
        });

        if (registroEmbarqueResponse === "") {
          Swal.fire({
            type: "success",
            title: "¡Éxito!",
            text: "El Embarque a sido editado exitosamente."
          });
        } else {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: "El Embarque no pudo ser editado, por favor intente de nuevo."
          });

          return false;
        }
      }
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeFile = async e => {
    let nombreAlta = [e.target.name] + "Alta";
    let nombrePreview = [e.target.name] + "Preview";
    let file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        [nombreAlta]: file,
        [nombrePreview]: reader.result,
        fileChanged: true
      });
    };
  };

  crearOptionEmpresa() {
    let listadoEmpresas = this.state.listadoEmpresas;

    return Object.keys(listadoEmpresas).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            defaultValue
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      }
    });
  }

  crearOptionConductores() {
    let listadoConductores = this.state.listadoConductores;

    return Object.keys(listadoConductores).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            defaultValue
            key={listadoConductores[keyName]["id"]}
            value={listadoConductores[keyName]["id"]}
          >
            {listadoConductores[keyName]["nombre"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoConductores[keyName]["id"]}
            value={listadoConductores[keyName]["id"]}
          >
            {listadoConductores[keyName]["nombre"]}
          </option>
        );
      }
    });
  }

  crearOptionUnidades() {
    let listadoUnidades = this.state.listadoUnidades;

    return Object.keys(listadoUnidades).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            key={listadoUnidades[keyName]["id"]}
            value={listadoUnidades[keyName]["id"]}
          >
            {listadoUnidades[keyName]["trailer"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoUnidades[keyName]["id"]}
            value={listadoUnidades[keyName]["id"]}
          >
            {listadoUnidades[keyName]["trailer"]}
          </option>
        );
      }
    });
  }

  async eliminarEmbarque(e) {
    e.preventDefault();

    Swal.fire({
      title: "¿Eliminar?",
      text: "Esta acción no podrá ser deshecha.",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!"
    }).then(async result => {
      if (result.value) {
        /* let eliminarEmpresaResponse = await eliminarEmbarque(
          JSON.parse(Cookies.get("usuario")),
          this.props.match.params.id
        ); */
      }
    });
  }

  habilitarEdicion() {
    this.setState({
      isEditing: !this.state.isEditing
    });
  }

  render() {
    let info, imagenPreview, imagenPreviewConductor;

    if (this.state.firmaResponsableAlta === "") {
      imagenPreview = "";
    } else {
      imagenPreview = (
        <img
          src={this.state.firmaResponsablePreview}
          alt="Preview"
          style={{ maxWidth: "250px" }}
        />
      );
    }

    if (this.state.firmaConductorAlta === "") {
      imagenPreviewConductor = "";
    } else {
      imagenPreviewConductor = (
        <img
          src={this.state.firmaConductorPreview}
          alt="Preview"
          style={{ maxWidth: "250px" }}
        />
      );
    }

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div>
          <div className="row mb-4">
            <div className="col-md-12">
              <a href={this.state.urlPdf} target="_blank">
                <button className="btn btn-primary">
                  Descargar formato SCT
                </button>
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <form action="#" onSubmit={e => this.validarEmbarque(e)}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Empresa:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <select
                                name="empresaAlta"
                                disabled={
                                  this.state.isEditing ? "" : "disabled"
                                }
                                className="form-control"
                                value={this.state.empresaAlta}
                                onChange={e => this.handleChange(e)}
                              >
                                {this.crearOptionEmpresa()}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Conductor:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <select
                                name="conductorAlta"
                                disabled={
                                  this.state.isEditing ? "" : "disabled"
                                }
                                className="form-control"
                                value={this.state.conductorAlta}
                                onChange={e => this.handleChange(e)}
                              >
                                {this.crearOptionConductores()}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Unidad:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <select
                                name="unidadAlta"
                                disabled={
                                  this.state.isEditing ? "" : "disabled"
                                }
                                className="form-control"
                                value={this.state.unidadAlta}
                                onChange={e => this.handleChange(e)}
                              >
                                {this.crearOptionUnidades()}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Número:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <input
                                type="text"
                                disabled={
                                  this.state.isEditing ? "" : "disabled"
                                }
                                className="form-control"
                                name="numeroAlta"
                                onChange={e => this.handleChange(e)}
                                value={this.state.numeroAlta}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Lugar de Salida:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                disabled={
                                  this.state.isEditing ? "" : "disabled"
                                }
                                name="lugarSalidaAlta"
                                onChange={e => this.handleChange(e)}
                                value={this.state.lugarSalidaAlta}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Fecha de Salida:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <input
                                type="date"
                                className="form-control"
                                disabled={
                                  this.state.isEditing ? "" : "disabled"
                                }
                                name="fechaSalidaAlta"
                                onChange={e => this.handleChange(e)}
                                value={this.state.fechaSalidaAlta}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Hora de Salida:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <input
                                type="time"
                                disabled={
                                  this.state.isEditing ? "" : "disabled"
                                }
                                className="form-control"
                                name="horaSalidaAlta"
                                onChange={e => this.handleChange(e)}
                                value={this.state.horaSalidaAlta}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Lugar de Llegada:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                disabled={
                                  this.state.isEditing ? "" : "disabled"
                                }
                                name="lugarLlegadaAlta"
                                onChange={e => this.handleChange(e)}
                                value={this.state.lugarLlegadaAlta}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Puntos Intermedios:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <input
                                type="text"
                                disabled={
                                  this.state.isEditing ? "" : "disabled"
                                }
                                className="form-control"
                                name="puntosIntAlta"
                                onChange={e => this.handleChange(e)}
                                value={this.state.puntosIntAlta}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Modalidad:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                disabled={
                                  this.state.isEditing ? "" : "disabled"
                                }
                                name="modalidadAlta"
                                onChange={e => this.handleChange(e)}
                                value={this.state.modalidadAlta}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Tipo de Servicio:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <select
                                name="tipoServicioAlta"
                                className="form-control"
                                disabled={
                                  this.state.isEditing ? "" : "disabled"
                                }
                                value={this.state.tipoServicioAlta}
                                onChange={e => this.handleChange(e)}
                              >
                                <option value="Federal">Federal</option>
                                <option value="Private">Private</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Firma Responsable:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <div className="form-group">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input custom-file-label"
                                    id="firmaResponsable"
                                    name="firmaResponsable"
                                    disabled={
                                      this.state.isEditing ? "" : "disabled"
                                    }
                                    onChange={e => this.handleChangeFile(e)}
                                    autoComplete="off"
                                    accept="image/*"
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="firmaResponsable"
                                  >
                                    Escoge una imagen
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Imagen:
                              </label>
                            </div>

                            <div className="col-md-9">{imagenPreview}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Firma Conductor:
                              </label>
                            </div>

                            <div className="col-md-9">
                              <div className="form-group">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input custom-file-label"
                                    id="firmaConductor"
                                    disabled={
                                      this.state.isEditing ? "" : "disabled"
                                    }
                                    name="firmaConductor"
                                    onChange={e => this.handleChangeFile(e)}
                                    autoComplete="off"
                                    accept="image/*"
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="firmaConductor"
                                  >
                                    Escoge una imagen
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-3">
                              <label htmlFor="" className="mt-2">
                                Imagen:
                              </label>
                            </div>

                            <div className="col-md-9">
                              {imagenPreviewConductor}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="embed-responsive embed-responsive-16by9"
                      style={{ height: "100%" }}
                    >
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230186.95356222498!2d-100.44318032036307!3d25.6490376398421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86629531b437f8f5%3A0xa3d3d3ca6ac89894!2sMonterrey%2C+N.L.!5e0!3m2!1ses-419!2smx!4v1553136346881"
                        width="600"
                        height="450"
                        frameBorder="0"
                        style={{ border: "0" }}
                        allowFullScreen
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <div
                      className={`col-md-12 text-center ${
                        this.state.isEditing ? "d-flex" : "d-none"
                      } justify-content-between`}
                    >
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={e => this.habilitarEdicion(e)}
                      >
                        Cancelar
                      </button>

                      <button type="submit" className="btn btn-primary">
                        Confirmar
                      </button>
                    </div>

                    <div
                      className={`col-md-12 text-center ${
                        this.state.isEditing ? "d-none" : "d-flex"
                      } justify-content-between`}
                    >
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={e => this.eliminarEmbarque(e)}
                      >
                        Eliminar
                      </button>

                      <button
                        type="button"
                        onClick={e => this.habilitarEdicion(e)}
                        className="btn btn-primary"
                      >
                        Editar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <Link to="/Embarques">
                  <button className="btn btn-primary">
                    Listado de Embarques
                  </button>
                </Link>
              </div>
            </div>

            <div className="row">
              <hr />
            </div>

            {info}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EmbarqueEditar);
