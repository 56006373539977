import React from "react";
import NoUser from "../assets/img/NoUser.jpg";

import axios from "axios";

export function formatImg(cell, row) {
  if (cell !== "") {
    return (
      <img
        src={cell}
        className="img-usuario-tabla rounded"
        alt="Imagen Usuario"
      />
    );
  } else {
    return (
      <img
        src={NoUser}
        className="img-usuario-tabla rounded"
        alt="Imagen Usuario"
      />
    );
  }
}

export function uploadImagen(usuario, imagen) {
  let imagenFormData = new FormData();
  imagenFormData.append("file", imagen);

  return axios
    .post("https://sybhs.azurewebsites.net/upload", imagenFormData, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
}
