import React from "react";
import "../../assets/css/login/login.css";
import "../../assets/css/main/main.css";

// img
import loginbg from "../../assets/img/loginbg.jpg";

const LoginLayout = props => {
  console.log("Login background photo by _M_V_ on Unsplash");

  let style = {
    background: "url('" + loginbg + "') no-repeat",
    backgroundSize: "cover"
  };

  return (
    <section className="h-100">
      <div className="backgroundimg" style={style} />

      <div className="position-absolute posabs">
        <div className="container-fluid mt-3">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body text-center">{props.children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginLayout;
