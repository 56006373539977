import axios from "axios";

export const getAllApiErrors = async usuario => {
  return await axios
    .get("https://sybhs.azurewebsites.net/ApiError", {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};
