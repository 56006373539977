import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../../../functions/login";
import { getAllConductores } from "../../../functions/Administracion/conductores";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";

// Empresas
import { getAllEmpresas } from "../../../functions/Gestion/empresas";

// Sweetalert2
import Swal from "sweetalert2";
import { getAllUnidades } from "../../../functions/Produccion/unidades";
import { registerEmbarque } from "../../../functions/Produccion/embarques";

class EmbarquesAlta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      listadoEmpresas: "",
      listadoConductores: "",
      listadoUnidades: "",
      empresaAlta: "",
      conductorAlta: "",
      unidadAlta: "",
      numeroAlta: "",
      lugarSalidaAlta: "",
      fechaSalidaAlta: "",
      horaSalidaAlta: "",
      lugarLlegadaAlta: "",
      puntosIntAlta: "",
      modalidadAlta: "",
      tipoServicioAlta: "Federal",
      firmaResponsableAlta: "",
      firmaResponsablePreview: "",
      firmaConductorAlta: "",
      firmaConductorPreview: ""
    };
  }

  async componentWillMount() {
    let listadoEmpresas = await getAllEmpresas(
      JSON.parse(Cookies.get("usuario"))
    );

    let listadoConductores = await getAllConductores(
      JSON.parse(Cookies.get("usuario"))
    );

    let listadoUnidades = await getAllUnidades(
      JSON.parse(Cookies.get("usuario"))
    );

    if (
      listadoEmpresas === 401 ||
      listadoConductores === 401 ||
      listadoUnidades === 401
    ) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    this.setState({
      listadoEmpresas: listadoEmpresas,
      empresaAlta: listadoEmpresas[0]["id"],
      listadoConductores: listadoConductores,
      conductorAlta: listadoConductores[0]["id"],
      listadoUnidades: listadoUnidades,
      unidadAlta: listadoUnidades[0]["id"],
      isLoading: false
    });
  }

  async validarEmbarque(e) {
    e.preventDefault();

    let nuevoEmbarque = {
      empresaAlta: this.state.empresaAlta,
      conductorAlta: this.state.conductorAlta,
      unidadAlta: this.state.unidadAlta,
      numeroAlta: this.state.numeroAlta,
      fechaSalidaAlta: this.state.fechaSalidaAlta,
      lugarSalidaAlta: this.state.lugarSalidaAlta,
      horaSalidaAlta: this.state.horaSalidaAlta,
      lugarLlegadaAlta: this.state.lugarLlegadaAlta,
      puntosIntAlta: this.state.puntosIntAlta,
      tipoServicioAlta: this.state.tipoServicioAlta,
      modalidadAlta: this.state.modalidadAlta,
      firmaResponsableAlta: this.state.firmaResponsableAlta,
      firmaConductorAlta: this.state.firmaConductorAlta
    };

    let isInputEmpty = Object.keys(nuevoEmbarque).some(function(keyName) {
      if (nuevoEmbarque[keyName] === "") {
        return true;
      }
    });

    if (isInputEmpty === true) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Favor de completar los campos de registro de Embarque"
      });

      return false;
    }

    this.setState({
      isLoading: true
    });

    let registroEmbarqueResponse = await registerEmbarque(
      JSON.parse(Cookies.get("usuario")),
      nuevoEmbarque
    );

    if (registroEmbarqueResponse === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    this.setState({
      isLoading: false
    });

    if (registroEmbarqueResponse === "") {
      Swal.fire({
        type: "success",
        title: "¡Éxito!",
        text: "El Embarque a sido creada exitosamente."
      });
    } else {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "El Embarque no pudo ser creado, por favor intente de nuevo."
      });

      return false;
    }

    this.setState({
      empresaAlta: "",
      conductorAlta: "",
      unidadAlta: "",
      numeroAlta: "",
      lugarSalidaAlta: "",
      fechaSalidaAlta: "",
      horaSalidaAlta: "",
      lugarLlegadaAlta: "",
      puntosIntAlta: "",
      modalidadAlta: "",
      tipoServicioAlta: "Federal",
      firmaResponsableAlta: "",
      firmaResponsablePreview: "",
      firmaConductorAlta: "",
      firmaConductorPreview: ""
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeFile = async e => {
    let nombreAlta = [e.target.name] + "Alta";
    let nombrePreview = [e.target.name] + "Preview";
    let file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        [nombreAlta]: file,
        [nombrePreview]: reader.result
      });
    };
  };

  crearOptionEmpresa() {
    let listadoEmpresas = this.state.listadoEmpresas;

    return Object.keys(listadoEmpresas).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            defaultValue
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      }
    });
  }

  crearOptionConductores() {
    let listadoConductores = this.state.listadoConductores;

    return Object.keys(listadoConductores).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            defaultValue
            key={listadoConductores[keyName]["id"]}
            value={listadoConductores[keyName]["id"]}
          >
            {listadoConductores[keyName]["nombre"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoConductores[keyName]["id"]}
            value={listadoConductores[keyName]["id"]}
          >
            {listadoConductores[keyName]["nombre"]}
          </option>
        );
      }
    });
  }

  crearOptionUnidades() {
    let listadoUnidades = this.state.listadoUnidades;

    return Object.keys(listadoUnidades).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            key={listadoUnidades[keyName]["id"]}
            value={listadoUnidades[keyName]["id"]}
          >
            {listadoUnidades[keyName]["trailer"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoUnidades[keyName]["id"]}
            value={listadoUnidades[keyName]["id"]}
          >
            {listadoUnidades[keyName]["trailer"]}
          </option>
        );
      }
    });
  }

  render() {
    let info, imagenPreview, imagenPreviewConductor;

    if (this.state.firmaResponsableAlta === "") {
      imagenPreview = "";
    } else {
      imagenPreview = (
        <img
          src={this.state.firmaResponsablePreview}
          alt="Preview"
          style={{ maxWidth: "250px" }}
        />
      );
    }

    if (this.state.firmaConductorAlta === "") {
      imagenPreviewConductor = "";
    } else {
      imagenPreviewConductor = (
        <img
          src={this.state.firmaConductorPreview}
          alt="Preview"
          style={{ maxWidth: "250px" }}
        />
      );
    }

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <form action="#" onSubmit={e => this.validarEmbarque(e)}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Empresa:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <select
                          name="empresaAlta"
                          className="form-control"
                          value={this.state.empresaAlta}
                          onChange={e => this.handleChange(e)}
                        >
                          {this.crearOptionEmpresa()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Conductor:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <select
                          name="conductorAlta"
                          className="form-control"
                          value={this.state.conductorAlta}
                          onChange={e => this.handleChange(e)}
                        >
                          {this.crearOptionConductores()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Unidad:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <select
                          name="unidadAlta"
                          className="form-control"
                          value={this.state.unidadAlta}
                          onChange={e => this.handleChange(e)}
                        >
                          {this.crearOptionUnidades()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Número:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="numeroAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.numeroAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Lugar de Salida:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="lugarSalidaAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.lugarSalidaAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Fecha de Salida:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="date"
                          className="form-control"
                          name="fechaSalidaAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.fechaSalidaAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Hora de Salida:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="time"
                          className="form-control"
                          name="horaSalidaAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.horaSalidaAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Lugar de Llegada:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="lugarLlegadaAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.lugarLlegadaAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Puntos Intermedios:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="puntosIntAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.puntosIntAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Modalidad:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="modalidadAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.modalidadAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Tipo de Servicio:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <select
                          name="tipoServicioAlta"
                          className="form-control"
                          value={this.state.tipoServicioAlta}
                          onChange={e => this.handleChange(e)}
                        >
                          <option value="Federal">Federal</option>
                          <option value="Private">Private</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Firma Responsable:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <div className="form-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input custom-file-label"
                              id="firmaResponsable"
                              name="firmaResponsable"
                              onChange={e => this.handleChangeFile(e)}
                              autoComplete="off"
                              accept="image/*"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="firmaResponsable"
                            >
                              Escoge una imagen
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Imagen:
                        </label>
                      </div>

                      <div className="col-md-9">{imagenPreview}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Firma Conductor:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <div className="form-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input custom-file-label"
                              id="firmaConductor"
                              name="firmaConductor"
                              onChange={e => this.handleChangeFile(e)}
                              autoComplete="off"
                              accept="image/*"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="firmaConductor"
                            >
                              Escoge una imagen
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Imagen:
                        </label>
                      </div>

                      <div className="col-md-9">{imagenPreviewConductor}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center">
                <button className="btn btn-primary">Registrar</button>
              </div>
            </div>
          </div>
        </form>
      );
    }

    return (
      <div className="card mt-3">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between">
                <h3 className="card-title">Alta de Embarque</h3>
                <Link to={`${process.env.PUBLIC_URL}/Embarques`}>
                  <button className="btn btn-primary">
                    Listado de Embarques
                  </button>
                </Link>
              </div>
            </div>

            <div className="row">
              <hr />
            </div>

            {info}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EmbarquesAlta);
