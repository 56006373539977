import React, { Component } from "react";
import { Link } from "react-router-dom";

class Operacion extends Component {
  render() {
    return (
      <li className="">
        <a
          href="#operacion"
          data-toggle="collapse"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          <i className="fas fa-list" />

          <span className="menu-title">Operacion</span>
        </a>

        <ul
          className="collapse list-unstyled main-menu"
          data-parent="#sidebar"
          id="operacion"
        >
          <li className="nav-item">
            <Link className="nav-link" to="/Registros">
              Registros
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Violaciones">
              Violaciones
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link className="nav-link" to="/Situaciones">
              Situaciones
            </Link>
          </li> */}
        </ul>

        <div className="active-submenu">
          <ul className="list-unstyled list-not-active">
            <li className="nav-item">
              <p className="m-1">
                <strong>Operación</strong>
              </p>

              <hr className="m-1" />
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/Registros">
                Registros
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Violaciones">
                Violaciones
              </Link>
            </li>
            {/*  <li className="nav-item">
              <Link className="nav-link" to="/Situaciones">
                Situaciones
              </Link>
            </li> */}
          </ul>
        </div>
      </li>
    );
  }
}

export default Operacion;
