import axios from "axios";
import { uploadImagen } from "../formato-imagen";

export const getAllEmpresas = usuario => {
  let empresasResponse = axios
    .get("https://sybhs.azurewebsites.net/Business", {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });

  return empresasResponse;
};

export const registerEmpresa = async (usuario, nombre, direccion, logo) => {
  let urlImagen = await uploadImagen(usuario, logo);

  if (urlImagen === 401) {
    return 401;
  }

  return axios
    .post(
      "https://sybhs.azurewebsites.net/Business",
      {
        id: 0,
        name: nombre,
        address: direccion,
        logo: urlImagen
      },
      {
        headers: {
          Authorization: usuario.token
        }
      }
    )
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export const getEmpresa = async (usuario, idEmpresa) => {
  return await axios
    .get("https://sybhs.azurewebsites.net/Business/" + idEmpresa, {
      headers: {
        Authorization: usuario.token
      }
    })
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export const editarEmpresa = async (
  usuario,
  nombre,
  direccion,
  logoFile,
  logoURL,
  isLogoChanged,
  idEmpresa
) => {
  let urlImagen = "";

  if (isLogoChanged) {
    urlImagen = await uploadImagen(usuario, logoFile);

    if (urlImagen === 401) {
      return 401;
    }
  } else {
    urlImagen = logoURL;
  }

  return axios
    .put(
      "https://sybhs.azurewebsites.net/Business",
      {
        id: idEmpresa,
        name: nombre,
        address: direccion,
        logo: urlImagen
      },
      {
        headers: {
          Authorization: usuario.token
        }
      }
    )
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};

export const eliminarEmpresa = async (usuario, idEmpresa) => {
  return await axios
    .delete(
      "https://sybhs.azurewebsites.net/Business/",
      { id: idEmpresa },
      {
        headers: {
          Authorization: usuario.token
        }
      }
    )
    .then(function(response) {
      return response["data"];
    })
    .catch(function(error) {
      return error.response["status"];
    });
};
