import React, { Component } from "react";
import { withRouter } from "react-router";
import { logout } from "../../../functions/login";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";
import Counter from "../../Varios/counter";

// Sweetalert2
import Swal from "sweetalert2";

// Datatables
import { Tbl } from "../../Layout/table";

// Funciones
import { formatText } from "../../../functions/Dev/apperrors";

class ReporteEmbarquesLugar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,

      dataSet: [],
      id: "id",
      columns: [
        {
          dataField: "description",
          text: "Description",
          formatter: formatText,
          sort: true
        },
        {
          dataField: "stackTrace",
          text: "StackTrace",
          formatter: formatText,
          sort: true
        },
        {
          dataField: "extra",
          text: "Extra",
          formatter: formatText,
          sort: true
        }
      ],
      rowEvents: {
        onClick: (e, row, rowIndex) => {
          this.props.history.push(
            `${process.env.PUBLIC_URL}/Detalle-Pregunta/${row["id"]}`
          );
        }
      },
      isLoading: true
    };

    this.refreshReporteEmbarquesLugar = this.refreshReporteEmbarquesLugar.bind(
      this
    );
  }

  async componentWillMount() {
    this.setState({
      isLoading: true
    });

    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    this.setState({
      dataSet: await this.obtenerReporte(),
      isLoading: false
    });

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  timeToLoad() {
    this.setState({
      timeLoad: this.state.timeLoad + 1
    });
  }

  async obtenerReporte() {
    /* let dataSetErrors = await getAllPreguntas(
      JSON.parse(Cookies.get("usuario"))
    );

    if (dataSetErrors === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    return dataSetErrors; */
  }

  async refreshReporteEmbarquesLugar(e) {
    this.setState({
      isLoading: true
    });

    this.setState({
      dataSet: await this.obtenerReporte(),
      isLoading: false
    });
  }

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      /* info = (
        <Tbl
          id={this.state.id}
          columns={this.state.columns}
          data={this.state.dataSet}
          rowEvents={this.state.rowEvents}
          actualizar={this.refreshReporteEmbarquesLugar}
        />
      ); */

      info = (
        <div>
          <h1 className="text-center">
            <i className="fas fa-exclamation-triangle" /> En Construcción{" "}
            <i className="fas fa-exclamation-triangle" />
          </h1>

          <div className="col-md-12 mt-5">
            <p className="lead">
              En esta pantalla podremos ver el reporte de Embarques por Lugar,
              donde veremos la información de Embarques basandonos en el lugar
              destino.
            </p>
          </div>

          <div className="col-md-12 mt-5">
            <Counter />
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-md-12">{info}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReporteEmbarquesLugar);
