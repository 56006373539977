import React, { Component } from "react";
import { Link } from "react-router-dom";

class Soporte extends Component {
  render() {
    return (
      <li className="">
        <a
          href="#soporte"
          data-toggle="collapse"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          <i className="fas fa-info-circle" />

          <span className="menu-title">Soporte</span>
        </a>

        <ul
          className="collapse list-unstyled main-menu"
          data-parent="#sidebar"
          id="soporte"
        >
          <li className="nav-item">
            <Link className="nav-link" to="/Preguntas-Frecuentes">
              Preguntas Frecuentes
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Reportar-Error">
              Reportar Error
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Contactanos">
              Contáctanos
            </Link>
          </li>
        </ul>

        <div className="active-submenu soporte-submenu">
          <ul className="list-unstyled list-not-active">
            <li className="nav-item">
              <p className="m-1">
                <strong>Soporte</strong>
              </p>

              <hr className="m-1" />
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/Preguntas-Frecuentes">
                Preguntas Frecuentes
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Reportar-Error">
                Reportar Error
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Contactanos">
                Contáctanos
              </Link>
            </li>
          </ul>
        </div>
      </li>
    );
  }
}

export default Soporte;
