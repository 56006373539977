import XlsExport from "xlsexport";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatoFecha } from "./Produccion/formato-fecha";
import { formatReglas } from "./Administracion/conductores";

export function finalizarDescarga(nombre, tipo, data) {
  let xls = new XlsExport(data, nombre);

  switch (tipo) {
    case "Excel":
      xls.exportToXLS(`${nombre}.xls`);
      break;

    case "CSV":
      xls.exportToCSV(`${nombre}.csv`);
      break;

    case "PDF":
      const headers = Object.keys(data[0]);

      let array = Object.keys(data).map(function(key) {
        return Object.keys(data[key]).map(function(keyInside) {
          switch (keyInside) {
            case ("fechaLlegada", "fechaSalida", "licVence", "fechaInicio"):
              if (data[key][keyInside].includes("/")) {
                return data[key][keyInside];
              } else {
                return formatoFecha(data[key][keyInside]);
              }

            case "fechaFin":
              if (data[key][keyInside].includes("/")) {
                return data[key][keyInside];
              } else {
                return formatoFecha(data[key][keyInside]);
              }

            case "reglas":
              return formatReglas(data[key][keyInside]);

            default:
              return data[key][keyInside];
          }
        });
      });

      let doc = new jsPDF();
      doc.autoTable({
        head: [headers],
        body: array
      });

      doc.save(`${nombre}.pdf`);
      break;
  }
}
