import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../../../functions/login";
import { registerConductor } from "../../../functions/Administracion/conductores";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";

// Empresas
import { getAllEmpresas } from "../../../functions/Gestion/empresas";

// Sweetalert2
import Swal from "sweetalert2";

class ConductoresAlta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      listadoEmpresas: "",
      empresaAlta: "",
      nombreCompletoAlta: "",
      nombreUsuarioAlta: "",
      correoAlta: "",
      contraseniaAlta: "",
      numeroLicenciaAlta: "",
      fechaLicenciaAlta: "",
      rulsetAlta: "Cargo",
      fotoAlta: "",
      fotoPreview: ""
    };
  }

  async componentWillMount() {
    let listadoEmpresas = await getAllEmpresas(
      JSON.parse(Cookies.get("usuario"))
    );

    if (listadoEmpresas === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    this.setState({
      listadoEmpresas: listadoEmpresas,
      empresaAlta: listadoEmpresas[0]["id"],
      isLoading: false
    });
  }

  async validarConductor(e) {
    e.preventDefault();

    let nuevoConductor = {
      empresaAlta: this.state.empresaAlta,
      nombreCompletoAlta: this.state.nombreCompletoAlta,
      nombreUsuarioAlta: this.state.nombreUsuarioAlta,
      correoAlta: this.state.correoAlta,
      contraseniaAlta: this.state.contraseniaAlta,
      numeroLicenciaAlta: this.state.numeroLicenciaAlta,
      fechaLicenciaAlta: this.state.fechaLicenciaAlta,
      rulsetAlta: this.state.rulsetAlta,
      fotoAlta: this.state.fotoAlta
    };

    if (
      nuevoConductor["empresaAlta"] === "" ||
      nuevoConductor["nombreCompletoAlta"] === "" ||
      nuevoConductor["nombreUsuarioAlta"] === "" ||
      nuevoConductor["correoAlta"] === "" ||
      nuevoConductor["contraseniaAlta"] === "" ||
      nuevoConductor["numeroLicenciaAlta"] === "" ||
      nuevoConductor["fechaLicenciaAlta"] === "" ||
      nuevoConductor["rulsetAlta"] === "" ||
      nuevoConductor["fotoAlta"] === ""
    ) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Favor de completar los campos de registro de Conductor"
      });

      return false;
    }

    this.setState({
      isLoading: true
    });

    let registroConductorResponse = await registerConductor(
      JSON.parse(Cookies.get("usuario")),
      nuevoConductor
    );

    if (registroConductorResponse === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a ingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    this.setState({
      isLoading: false
    });

    if (registroConductorResponse === "") {
      Swal.fire({
        type: "success",
        title: "¡Éxito!",
        text: "El Conductor a sido creado exitosamente."
      });
    } else {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "El Conductor no pudo ser creado, por favor intente de nuevo."
      });

      return false;
    }

    this.setState({
      empresaAlta: "",
      nombreCompletoAlta: "",
      nombreUsuarioAlta: "",
      contraseniaAlta: "",
      correoAlta: "",
      rolAlta: "",
      fotoAlta: "",
      fotoPreview: ""
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeFile = async e => {
    let file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        fotoAlta: file,
        fotoPreview: reader.result
      });
    };
  };

  crearOptionEmpresa() {
    let listadoEmpresas = this.state.listadoEmpresas;

    return Object.keys(listadoEmpresas).map(function(keyName, index) {
      if (index === 0) {
        return (
          <option
            defaultValue
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      } else {
        return (
          <option
            key={listadoEmpresas[keyName]["id"]}
            value={listadoEmpresas[keyName]["id"]}
          >
            {listadoEmpresas[keyName]["name"]}
          </option>
        );
      }
    });
  }

  render() {
    let info, imagenPreview;

    if (this.state.fotoAlta === "") {
      imagenPreview = "";
    } else {
      imagenPreview = (
        <img
          src={this.state.fotoPreview}
          alt="Logo Preview"
          style={{ maxWidth: "250px" }}
        />
      );
    }

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <form action="#" onSubmit={e => this.validarConductor(e)}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Empresa:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <select
                          name="empresaAlta"
                          className="form-control"
                          value={this.state.empresaAlta}
                          onChange={e => this.handleChange(e)}
                        >
                          {this.crearOptionEmpresa()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Nombre completo:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="nombreCompletoAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.nombreCompletoAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Nombre de Usuario:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="nombreUsuarioAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.nombreUsuarioAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          E-Mail:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="email"
                          className="form-control"
                          name="correoAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.correoAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Contraseña:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="password"
                          className="form-control"
                          name="contraseniaAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.contraseniaAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Número de Licencia:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="numeroLicenciaAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.numeroLicenciaAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Fecha de Licencia:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <input
                          type="date"
                          className="form-control"
                          name="fechaLicenciaAlta"
                          onChange={e => this.handleChange(e)}
                          value={this.state.fechaLicenciaAlta}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Tipo de Ruleset:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <select
                          name="rulsetAlta"
                          className="form-control"
                          value={this.state.rulsetAlta}
                          onChange={e => this.handleChange(e)}
                        >
                          <option defaultValue value="Cargo">
                            Cargo
                          </option>
                          <option value="Passenger">Passenger</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Foto de Usuario:
                        </label>
                      </div>

                      <div className="col-md-9">
                        <div className="form-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input custom-file-label"
                              id="logoAlta"
                              name="fotoAlta"
                              onChange={e => this.handleChangeFile(e)}
                              autoComplete="off"
                              accept="image/*"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="logoAlta"
                            >
                              Escoge una imagen
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="offset-3 col-6 ">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="" className="mt-2">
                          Imagen:
                        </label>
                      </div>

                      <div className="col-md-9">{imagenPreview}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center">
                <button className="btn btn-primary">Registrar</button>
              </div>
            </div>
          </div>
        </form>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <Link to="/Conductores">
                  <button className="btn btn-primary">
                    Listado de Conductores
                  </button>
                </Link>
              </div>
            </div>

            <div className="row">
              <hr />
            </div>

            {info}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConductoresAlta);
