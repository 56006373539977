export function formatoFecha(cell, row) {
  if (cell > 0) {
    let date = new Date();
    date.setTime(parseInt(cell));

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    let strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      "  " +
      strTime
    );
  } else {
    return "Sin fecha";
  }
}

export function formatoFechaSinHora(cell, row) {
  if (cell > 0) {
    let date = new Date();
    date.setTime(parseInt(cell));

    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  } else {
    return "Sin fecha";
  }
}
