import React, { Component } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { MemoryRouter } from "react-router";

// Componentes Base
import Layout from "./modules/Layout/layout";
import LoginLayout from "./modules/Layout/login-layout";
import LandingPage from "./modules/Layout/landing";
import NotFound from "./modules/Layout/not-found.js";
import Login from "./modules/Login/login";
import ForgotPass from "./modules/Login/forgot-pass";
import RestaurarPass from "./modules/Login/restaurar-pass";
import Perfil from "./modules/Perfil/perfil";

// Componentes Funcionales
import Home from "./modules/Home/home";

// Empresas
import Empresas from "./modules/Gestion/Empresas/empresas";
import EmpresaAlta from "./modules/Gestion/Empresas/empresas-alta";
import EmpresaEdita from "./modules/Gestion/Empresas/empresas-edita";

// Usuarios
import Usuarios from "./modules/Gestion/Usuarios/usuarios";
import UsuarioAlta from "./modules/Gestion/Usuarios/usuarios-alta";
import UsuarioEdita from "./modules/Gestion/Usuarios/usuarios-edita";

// Conductores
import Conductores from "./modules/Administracion/Conductores/conductores";
import ConductoresAlta from "./modules/Administracion/Conductores/conductores-alta";
import ConductorEdita from "./modules/Administracion/Conductores/conductores-edita";

// Lugares Autorizados
import AuthPlaces from "./modules/Administracion/AuthPlaces/authplaces";
import AuthPlaceAlta from "./modules/Administracion/AuthPlaces/alta-authplaces";
import AuthPlaceEdita from "./modules/Administracion/AuthPlaces/edita-authplaces";

// Unidades
import Unidades from "./modules/Produccion/Unidades/unidades";
import UnidadesAlta from "./modules/Produccion/Unidades/unidades-alta";
import UnidadEdita from "./modules/Produccion/Unidades/unidades-edita";

// Embarques
import Embarques from "./modules/Produccion/Embarques/embarques";
import EmbarquesAlta from "./modules/Produccion/Embarques/embarques-alta";
import EmbarqueEditar from "./modules/Produccion/Embarques/embarques-edita";

// Registros
import Registros from "./modules/Operacion/Registros/registros";

// Violaciones
import Violaciones from "./modules/Operacion/Violaciones/violaciones";

// Situaciones
import Situaciones from "./modules/Operacion/Situaciones/situaciones";

// AppError
import AppErrors from "./modules/Dev/AppErrors/apperrors";
import DetalleAppError from "./modules/Dev/AppErrors/detalle-apperror";

// API Errors
import ApiErrors from "./modules/Dev/APIErrors/apierrors";

// Preguntas
import Preguntas from "./modules/Dev/Preguntas/preguntas";

// Reportes
import ReporteViolaciones from "./modules/Reportes/Violaciones/reporteviolaciones";
import ReporteConductaChoferes from "./modules/Reportes/ConductaChoferes/reporteconductachoferes";
import ReporteEmbarquesUnidad from "./modules/Reportes/EmbarquesUnidad/reporteembarquesunidad";
import ReporteEmbarquesCliente from "./modules/Reportes/EmbarquesCliente/reporteembarquescliente";
import ReporteEmbarquesLugar from "./modules/Reportes/EmbarquesLugar/reporteembarqueslugar";
import ReporteCargaCombustible from "./modules/Reportes/CargaCombustible/reportecargacombustible";

// Soporte
import PreguntasFrecuentes from "./modules/Soporte/preguntas";
import ReportarError from "./modules/Soporte/reportarerror";
import Contactanos from "./modules/Soporte/contactanos";

// Mi Empresa
import MiEmpresa from "./modules/MiEmpresa/miEmpresa";

// Config
import Configuracion from "./modules/Configuracion/configuracion";
import ConfirmarSMS from "./modules/Configuracion/confirmarsms";

class Routes extends Component {
  render() {
    return (
      <MemoryRouter>
        <Switch>
          <Route
            exact
            path="/Login"
            render={() => (
              <LoginLayout>
                <Login />
              </LoginLayout>
            )}
          />

          <Route
            exact
            path="/forgot-password"
            render={() => (
              <LoginLayout>
                <ForgotPass />
              </LoginLayout>
            )}
          />

          <Route
            exact
            path="/reset/:isReset/:id/:token"
            render={() => (
              <LoginLayout>
                <RestaurarPass />
              </LoginLayout>
            )}
          />

          <Route
            exact
            path="/Dashboard"
            render={() => (
              <Layout>
                <Home title="Bienvenido a SyBHS" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Perfil"
            render={() => (
              <Layout>
                <Perfil title="Perfil" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Empresas"
            render={() => (
              <Layout>
                <Empresas title="Empresas" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Alta-Empresa"
            render={() => (
              <Layout>
                <EmpresaAlta title="Alta de Empresa" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Editar-Empresa/:id"
            render={() => (
              <Layout>
                <EmpresaEdita title="Detalle de Empresa" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Usuarios"
            render={() => (
              <Layout>
                <Usuarios title="Usuarios" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Alta-Usuario"
            render={() => (
              <Layout>
                <UsuarioAlta title="Alta de Usuario" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Editar-Usuario/:id"
            render={() => (
              <Layout>
                <UsuarioEdita title="Detalle de Usuario" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Conductores"
            render={() => (
              <Layout>
                <Conductores title="Conductores" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Alta-Conductor"
            render={() => (
              <Layout>
                <ConductoresAlta title="Alta de Conductor" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Editar-Conductor/:id"
            render={() => (
              <Layout>
                <ConductorEdita title="Detalle de Conductor" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Unidades"
            render={() => (
              <Layout>
                <Unidades title="Unidades" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Alta-Unidad"
            render={() => (
              <Layout>
                <UnidadesAlta title="Unidades" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Editar-Unidad/:id"
            render={() => (
              <Layout>
                <UnidadEdita title="Detalle de Unidad" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Embarques"
            render={() => (
              <Layout>
                <Embarques title="Embarques" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Alta-Embarque"
            render={() => (
              <Layout>
                <EmbarquesAlta title="Embarques" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Editar-Embarque/:id"
            render={() => (
              <Layout>
                <EmbarqueEditar title="Detalle de Embarque" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Registros"
            render={() => (
              <Layout>
                <Registros title="Registros" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Violaciones"
            render={() => (
              <Layout>
                <Violaciones title="Violaciones" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/AppErrors"
            render={() => (
              <Layout>
                <AppErrors title="Errores de App" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Detalle-AppError/:id"
            render={() => (
              <Layout>
                <DetalleAppError title="Detalle de error de App" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Situaciones"
            render={() => (
              <Layout>
                <Situaciones title="Situaciones" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Lugares-Autorizados"
            render={() => (
              <Layout>
                <AuthPlaces title="Lugares Autorizados" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Alta-Lugar"
            render={() => (
              <Layout>
                <AuthPlaceAlta title="Alta de Lugar Autorizado" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Edita-Lugar"
            render={() => (
              <Layout>
                <AuthPlaceEdita title="Detalle de Lugar Autorizado" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/API-Errors"
            render={() => (
              <Layout>
                <ApiErrors title="Errores de API" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Preguntas"
            render={() => (
              <Layout>
                <Preguntas title="Preguntas" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Reporte-Violaciones"
            render={() => (
              <Layout>
                <ReporteViolaciones title="Reporte de Violaciones" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Reporte-Conducta-Choferes"
            render={() => (
              <Layout>
                <ReporteConductaChoferes title="Reporte Conducta de Choferes" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Reporte-Embarques-Unidad"
            render={() => (
              <Layout>
                <ReporteEmbarquesUnidad title="Reporte Embarques por Unidad" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Reporte-Embarques-Conductor"
            render={() => (
              <Layout>
                <ReporteEmbarquesCliente title="Reporte Embarques por Conductor" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Reporte-Embarques-Lugar"
            render={() => (
              <Layout>
                <ReporteEmbarquesLugar title="Reporte Embarques por Lugar" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Reporte-Lugar-Autorizado-Ciudad"
            render={() => (
              <Layout>
                <ReporteCargaCombustible title="Reporte Lugares Autorizados por Ciudad" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Preguntas-Frecuentes"
            render={() => (
              <Layout>
                <PreguntasFrecuentes title="Preguntas Frecuentes" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Reportar-Error"
            render={() => (
              <Layout>
                <ReportarError title="Reportar Error" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Contactanos"
            render={() => (
              <Layout>
                <Contactanos title="Contáctanos" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Mi-Empresa"
            render={() => (
              <Layout>
                <MiEmpresa title="Mi Empresa" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Configuracion"
            render={() => (
              <Layout>
                <Configuracion title="Configuración" />
              </Layout>
            )}
          />

          <Route
            exact
            path="/Confirmar-SMS"
            render={() => (
              <Layout>
                <ConfirmarSMS title="Confirmar vía SMS" />
              </Layout>
            )}
          />

          <Route exact path="/" render={() => <LandingPage />} />

          <Route render={() => <NotFound />} />
        </Switch>
      </MemoryRouter>
    );
  }
}

export default Routes;
