import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../../../functions/login";
import { registerEmpresa } from "../../../functions/Gestion/empresas";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";

// Sweetalert2
import Swal from "sweetalert2";

class EmpresaAlta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      nombreAlta: "",
      direccionAlta: "",
      logoAlta: "",
      logoPreview: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeFile = async e => {
    let file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        logoAlta: file,
        logoPreview: reader.result
      });
    };
  };

  async validarEmpresa(e) {
    e.preventDefault();

    let nombreEmpresa = this.state.nombreAlta;
    let direccionEmpresa = this.state.direccionAlta;
    let logoEmpresa = this.state.logoAlta;

    if (nombreEmpresa === "" || direccionEmpresa === "" || logoEmpresa === "") {
      alert("Favor de completar los campos de registro de Empresa");
      return false;
    }

    this.setState({
      isLoading: true
    });

    let registroEmpresaResponse = await registerEmpresa(
      JSON.parse(Cookies.get("usuario")),
      nombreEmpresa,
      direccionEmpresa,
      logoEmpresa
    );

    if (registroEmpresaResponse === 401) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Se ha terminado tu sesión, por favor vuelve a reingresar."
      });

      logout();
      this.props.history.push("/Login");
      return false;
    }

    this.setState({
      isLoading: false
    });

    if (registroEmpresaResponse === "") {
      Swal.fire({
        type: "success",
        title: "¡Éxito!",
        text: "La Empresa a sido creado exitosamente."
      });
    } else {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "La Empresa no pudo ser creada, por favor intente de nuevo."
      });

      return false;
    }

    this.setState({
      isLoading: false,
      nombreAlta: "",
      direccionAlta: "",
      logoAlta: ""
    });
  }

  render() {
    let info, imgPreview;

    if (this.state.logoPreview === "") {
      imgPreview = "";
    } else {
      imgPreview = (
        <img
          src={this.state.logoPreview}
          alt="Logo Preview"
          style={{ maxWidth: "250px" }}
        />
      );
    }

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div className="col-md-12">
          <div className="row">
            <div className="offset-3 col-6 ">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="" className="mt-2">
                      Nombre:
                    </label>
                  </div>

                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      name="nombreAlta"
                      onChange={e => this.handleChange(e)}
                      value={this.state.nombreAlta}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="offset-3 col-6 ">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="" className="mt-2">
                      Dirección:
                    </label>
                  </div>

                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      name="direccionAlta"
                      onChange={e => this.handleChange(e)}
                      value={this.state.direccionAlta}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="offset-3 col-6 ">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="" className="mt-2">
                      Logo:
                    </label>
                  </div>

                  <div className="col-md-9">
                    <div className="form-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input custom-file-label"
                          id="logoAlta"
                          name="logoAlta"
                          onChange={e => this.handleChangeFile(e)}
                          autoComplete="off"
                          accept="image/*"
                        />
                        <label className="custom-file-label" htmlFor="logoAlta">
                          Escoge una imagen
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="offset-3 col-6 ">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="" className="mt-2">
                      Imagen:
                    </label>
                  </div>

                  <div className="col-md-9">{imgPreview}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 text-center">
            <button className="btn btn-primary">Registrar</button>
          </div>
        </div>
      );
    }

    return (
      <div className="card ">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <Link to={`${process.env.PUBLIC_URL}/Empresas`}>
                  <button className="btn btn-primary">
                    Listado de Empresas
                  </button>
                </Link>
              </div>
            </div>

            <form action="#" onSubmit={e => this.validarEmpresa(e)}>
              <div className="row">{info}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EmpresaAlta);
