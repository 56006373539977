import React, { Component } from "react";
import Header from "./header";
import LeftMenu from "./left-menu";
import "../../assets/css/main/main.css";

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadTime: "?"
    };

    this.actualizarLoadTime = this.actualizarLoadTime.bind(this);
  }

  actualizarLoadTime(tiempoCarga) {
    this.setState({
      loadTime: tiempoCarga
    });
  }

  render() {
    // El Children que ocupamos,
    // le agregamos la función de cambio al timer del footer
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        actualizarLoadTime: this.actualizarLoadTime
      });
    });

    return (
      <div className="">
        <section className="" id="main">
          <div className="wrapper">
            <LeftMenu />

            <div id="content" className="">
              <Header title={this.props.children.props.title} />

              <div className="p-3 flex-1">{children}</div>

              <footer className="footer-div d-flex justify-content-between align-items-center">
                <div className="text-center">
                  <p className="m-0">
                    Tiempo de carga: <span>{this.state.loadTime}</span>ms
                  </p>
                </div>
                <div className="text-center">
                  <p className="m-0">
                    © Copyright 2019 SyBHS - Todos los derechos reservados.
                  </p>
                </div>
              </footer>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Layout;
