import React, { Component } from "react";
import { withRouter } from "react-router";
import { ResponsiveContainer, PieChart, Pie, Legend, Tooltip } from "recharts";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from "recharts";
import { LineChart, Line } from "recharts";
import { AreaChart, Area } from "recharts";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis
} from "recharts";
import { ScatterChart, Scatter } from "recharts";

import Counter from "../Varios/counter";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      data: []
    };
  }

  async componentWillMount() {
    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  render() {
    const datascatter = [
      { x: 100, y: 200, z: 200 },
      { x: 120, y: 100, z: 260 },
      { x: 170, y: 300, z: 400 },
      { x: 140, y: 250, z: 280 },
      { x: 150, y: 400, z: 500 },
      { x: 110, y: 280, z: 200 }
    ];

    const data01 = [
      { name: "Senda", value: 400 },
      { name: "Coca-Cola", value: 300 },
      { name: "PepsiCo.", value: 300 },
      { name: "DHL", value: 200 },
      { name: "Fedex", value: 278 },
      { name: "Little Caesars", value: 189 }
    ];

    const dataradar = [
      {
        subject: "Senda",
        A: 120,
        B: 110,
        fullMark: 150
      },
      {
        subject: "Coca-Cola",
        A: 98,
        B: 130,
        fullMark: 150
      },
      {
        subject: "PepsiCo",
        A: 86,
        B: 130,
        fullMark: 150
      },
      {
        subject: "Little Caesars",
        A: 99,
        B: 100,
        fullMark: 150
      },
      {
        subject: "Fedex",
        A: 85,
        B: 90,
        fullMark: 150
      },
      {
        subject: "UPS",
        A: 65,
        B: 85,
        fullMark: 150
      }
    ];

    const data = [
      {
        name: "Senda",
        Violaciones: 200,
        amt: 2400
      },
      {
        name: "Coca-Cola",
        Violaciones: 105,
        amt: 2210
      },
      {
        name: "PepsiCo",
        Violaciones: 452,
        amt: 2290
      }
    ];

    const dataLine = [
      {
        name: "Monterrey",
        Salida: 4000,
        Destino: 2400,
        amt: 2400
      },
      {
        name: "Guadalajara",
        Salida: 3000,
        Destino: 1398,
        amt: 2210
      },
      {
        name: "CDMX",
        Salida: 2000,
        Destino: 9800,
        amt: 2290
      }
    ];

    const datasimplearea = [
      {
        name: "Juan A.",
        uv: 4000,
        pv: 2400,
        amt: 2400
      },
      {
        name: "Pedro B.",
        uv: 3000,
        pv: 1398,
        amt: 2210
      },
      {
        name: "María C.",
        uv: 2000,
        pv: 9800,
        amt: 2290
      },
      {
        name: "Leo D.",
        uv: 2780,
        pv: 3908,
        amt: 2000
      },
      {
        name: "Javier E",
        uv: 1890,
        pv: 4800,
        amt: 2181
      }
    ];

    const dataBubble1 = [
      { hour: "12a", index: 1, value: 170 },
      { hour: "1a", index: 1, value: 180 },
      { hour: "2a", index: 1, value: 150 },
      { hour: "3a", index: 1, value: 120 },
      { hour: "4a", index: 1, value: 200 },
      { hour: "5a", index: 1, value: 300 },
      { hour: "6a", index: 1, value: 400 },
      { hour: "7a", index: 1, value: 200 },
      { hour: "8a", index: 1, value: 100 },
      { hour: "9a", index: 1, value: 150 },
      { hour: "10a", index: 1, value: 160 },
      { hour: "11a", index: 1, value: 170 },
      { hour: "12a", index: 1, value: 180 },
      { hour: "1p", index: 1, value: 144 },
      { hour: "2p", index: 1, value: 166 },
      { hour: "3p", index: 1, value: 145 },
      { hour: "4p", index: 1, value: 150 },
      { hour: "5p", index: 1, value: 170 },
      { hour: "6p", index: 1, value: 180 },
      { hour: "7p", index: 1, value: 165 },
      { hour: "8p", index: 1, value: 130 },
      { hour: "9p", index: 1, value: 140 },
      { hour: "10p", index: 1, value: 170 },
      { hour: "11p", index: 1, value: 180 }
    ];

    const dataBubble2 = [
      { hour: "12a", index: 1, value: 160 },
      { hour: "1a", index: 1, value: 180 },
      { hour: "2a", index: 1, value: 150 },
      { hour: "3a", index: 1, value: 120 },
      { hour: "4a", index: 1, value: 200 },
      { hour: "5a", index: 1, value: 300 },
      { hour: "6a", index: 1, value: 100 },
      { hour: "7a", index: 1, value: 200 },
      { hour: "8a", index: 1, value: 100 },
      { hour: "9a", index: 1, value: 150 },
      { hour: "10a", index: 1, value: 160 },
      { hour: "11a", index: 1, value: 160 },
      { hour: "12a", index: 1, value: 180 },
      { hour: "1p", index: 1, value: 144 },
      { hour: "2p", index: 1, value: 166 },
      { hour: "3p", index: 1, value: 145 },
      { hour: "4p", index: 1, value: 150 },
      { hour: "5p", index: 1, value: 160 },
      { hour: "6p", index: 1, value: 180 },
      { hour: "7p", index: 1, value: 165 },
      { hour: "8p", index: 1, value: 130 },
      { hour: "9p", index: 1, value: 140 },
      { hour: "10p", index: 1, value: 160 },
      { hour: "11p", index: 1, value: 180 }
    ];

    const datalinecurve = [
      {
        name: "Monterrey",
        uv: 31.47,
        pv: 2400,
        fill: "#8884d8"
      },
      {
        name: "CDMX",
        uv: 26.69,
        pv: 4567,
        fill: "#83a6ed"
      },
      {
        name: "Guadalajara",
        uv: 15.69,
        pv: 1398,
        fill: "#8dd1e1"
      },
      {
        name: "Puebla",
        uv: 8.22,
        pv: 9800,
        fill: "#82ca9d"
      },
      {
        name: "Tlaxcala",
        uv: 8.63,
        pv: 3908,
        fill: "#a4de6c"
      },
      {
        name: "Nuevo Toledo",
        uv: 2.63,
        pv: 4800,
        fill: "#d0ed57"
      },
      {
        name: "Fuera de México",
        uv: 6.67,
        pv: 4800,
        fill: "#ffc658"
      }
    ];

    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Counter />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="text-center">
                <strong>Embarques por Cliente</strong>
              </p>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart width={400} height={400}>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={data01}
                    fill="#53b5ab"
                    label
                  />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>

            <div className="col-md-4">
              <p className="text-center">
                <strong>Violaciones</strong>
              </p>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Violaciones" fill="#53b5ab" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="col-md-4">
              <p className="text-center">
                <strong>Embarques por Lugar</strong>
              </p>
              <ResponsiveContainer width="100%" height={250}>
                <LineChart
                  data={dataLine}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="Destino"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="Salida" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-4">
              <p className="text-center">
                <strong>Conducta de Conductores</strong>
              </p>
              <ResponsiveContainer width="100%" height={250}>
                <AreaChart
                  data={datasimplearea}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="uv"
                    stroke="#8884d8"
                    fill="#53b5ab"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>

            <div className="col-md-4">
              <p className="text-center">
                <strong>Violaciones por Cliente</strong>
              </p>
              <ResponsiveContainer width="100%" height={250}>
                <RadarChart data={dataradar}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="subject" />
                  <PolarRadiusAxis angle={30} domain={[0, 150]} />
                  <Radar
                    name="Violaciones"
                    dataKey="A"
                    stroke="#8884d8"
                    fill="#8884d8"
                    fillOpacity={0.6}
                  />
                  <Radar
                    name="Conductor"
                    dataKey="B"
                    stroke="#82ca9d"
                    fill="#82ca9d"
                    fillOpacity={0.6}
                  />
                  <Legend />
                </RadarChart>
              </ResponsiveContainer>
            </div>

            <div className="col-md-4">
              <p className="text-center">
                <strong>Embarques por Lugar</strong>
              </p>
              <ResponsiveContainer width="100%" height={250}>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20
                  }}
                >
                  <CartesianGrid />
                  <XAxis type="number" dataKey="x" name="stature" unit="cm" />
                  <YAxis type="number" dataKey="y" name="weight" unit="kg" />
                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                  <Scatter name="A school" data={datascatter} fill="#8884d8" />
                </ScatterChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
