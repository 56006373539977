import React, { Component } from "react";
import { withRouter } from "react-router";
import { logout } from "../../../functions/login";
import Cookies from "js-cookie";
import Loader from "../../Layout/loader";
import Descargas from "../../Layout/descargas";

// Funciones
import { formatImg } from "../../../functions/formato-imagen";
import {
  getAllConductores,
  formatReglas
} from "../../../functions/Administracion/conductores";
import { formatoFechaSinHora } from "../../../functions/Produccion/formato-fecha";

// Datatables
import { Tbl } from "../../Layout/table";

class Conductores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLoad: 0,
      dataSet: [],
      id: "id",
      columns: [
        {
          dataField: "foto",
          text: "Foto",
          formatter: formatImg,
          classes: "text-center",
          sort: false
        },
        {
          dataField: "nombre",
          text: "Nombre",
          sort: true
        },
        {
          dataField: "empresa",
          text: "Empresa",
          sort: true
        },
        {
          dataField: "usuario",
          text: "Usuario",
          sort: true
        },
        {
          dataField: "correo",
          text: "Correo",
          sort: true
        },
        {
          dataField: "licencia",
          text: "Licencia",
          sort: true
        },
        {
          dataField: "licVence",
          text: "Licencia Vence",
          formatter: formatoFechaSinHora,
          sort: true
        },
        {
          dataField: "reglas",
          text: "Reglas",
          sort: true,
          formatter: formatReglas
        }
      ],
      rowEvents: {
        onClick: (e, row, rowIndex) => {
          this.props.history.push(
            `${process.env.PUBLIC_URL}/Editar-Conductor/${row["id"]}`
          );
        }
      },
      isLoading: true
    };

    this.refreshConductores = this.refreshConductores.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.tiempoCarga = setInterval(() => this.timeToLoad(), 1);

    this.setState({
      dataSet: await this.obtenerConductores(),
      isLoading: false
    });

    clearInterval(this.tiempoCarga);

    // Lo mandamos al parent
    this.props.actualizarLoadTime(this.state.timeLoad);
  }

  timeToLoad() {
    this.setState({
      timeLoad: this.state.timeLoad + 1
    });
  }

  async obtenerConductores() {
    let dataSetConductores = await getAllConductores(
      JSON.parse(Cookies.get("usuario"))
    );

    if (dataSetConductores === 401) {
      logout();
      this.props.history.push("/Login");
      return false;
    }

    return dataSetConductores;
  }

  async refreshConductores(e) {
    this.setState({
      isLoading: true
    });

    this.setState({
      dataSet: await this.obtenerConductores(),
      isLoading: false
    });
  }

  render() {
    let info;

    if (this.state.isLoading) {
      info = <Loader />;
    } else {
      info = (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Tbl
                id={this.state.id}
                columns={this.state.columns}
                data={this.state.dataSet}
                rowEvents={this.state.rowEvents}
                nuevoTexto="Nuevo Conductor"
                nuevoLink="Alta-Conductor"
                actualizar={this.refreshConductores}
              />

              {Object.entries(this.state.dataSet).length === 0 ? (
                ""
              ) : (
                <Descargas dataSet={this.state.dataSet} nombre="Conductores" />
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body loader-inside">{info}</div>
      </div>
    );
  }
}

export default withRouter(Conductores);
