import React, { Component } from "react";

class Counter extends Component {
  state = {
    deadline: "May, 31, 2019",
    days: "",
    hours: "",
    minutes: "",
    seconds: ""
  };

  componentDidMount = () => {
    setInterval(() => this.getTimeUntil(), 1000);
  };

  getTimeUntil() {
    const time = Date.parse(this.state.deadline) - Date.parse(new Date());

    if (time < 0) {
      console.log("Date passed");
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));

      this.setState({
        days,
        hours,
        minutes,
        seconds
      });
    }
  }

  render() {
    return (
      <div className="container divCounter">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center">
              <h3>Etapa 4</h3>
              <h1>Fecha para habilitar esta pantalla</h1>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: "25px" }}>
          <div className="col-xs-6 col-md-3 text-center">
            <h2>Días</h2>

            <h3>
              <span>{this.state.days}</span>
            </h3>
          </div>

          <div className="col-xs-6 col-md-3 text-center">
            <h2>Horas</h2>
            <h3>
              <span>{this.state.hours}</span>
            </h3>
          </div>

          <div className="col-xs-6 col-md-3 text-center">
            <h2>Minutos</h2>
            <h3>
              <span>{this.state.minutes}</span>
            </h3>
          </div>

          <div className="col-xs-6 col-md-3 text-center">
            <h2>Segundos</h2>
            <h3>
              <span>{this.state.seconds}</span>
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Counter;
