import React, { Component } from "react";
import { Link } from "react-router-dom";

class AppErrors extends Component {
  render() {
    return (
      <li className="">
        <a
          href="#desarrollo"
          data-toggle="collapse"
          data-target="#desarrollo"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          <i className="fab fa-dev" />{" "}
          <span className="menu-title">Desarrollo</span>
        </a>

        <ul
          className="collapse list-unstyled main-menu"
          data-parent="#sidebar"
          id="desarrollo"
        >
          <li className="nav-item">
            <Link className="nav-link" to="/AppErrors">
              Errores de Aplicación
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/API-Errors">
              Errores de API
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Preguntas">
              Preguntas
            </Link>
          </li>
        </ul>

        <div className="active-submenu">
          <ul className="list-unstyled list-not-active">
            <li className="nav-item">
              <p className="m-1">
                <strong>Desarrollo</strong>
              </p>

              <hr className="m-1" />
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/AppErrors">
                Errores de Aplicación
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/API-Errors">
                Errores de API
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Preguntas">
                Preguntas
              </Link>
            </li>
          </ul>
        </div>
      </li>
    );
  }
}

export default AppErrors;
