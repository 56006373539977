import React, { Component } from "react";
import { finalizarDescarga } from "../../functions/descargas";
import { formatoFecha } from "../../functions/Produccion/formato-fecha";

class Descargas extends Component {
  descargar(tipo) {
    let objetos = this.props.dataSet;

    objetos.map(function(keyName, index) {
      delete keyName["id"];
      delete keyName["foto"];
      delete keyName["logo"];
      delete keyName["imagen"];
    });

    finalizarDescarga(this.props.nombre, tipo, objetos);
  }

  render() {
    return (
      <div className="btn-exportar d-flex justify-content-end">
        <button
          className="btn btn-primary mr-3"
          onClick={e => this.descargar("Excel")}
        >
          Descargar Excel
        </button>

        <button
          className="btn btn-primary mr-3"
          onClick={e => this.descargar("CSV")}
        >
          Descargar CSV
        </button>

        <button
          className="btn btn-primary"
          onClick={e => this.descargar("PDF")}
        >
          Descargar PDF
        </button>
      </div>
    );
  }
}

export default Descargas;
